/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'features/authentication/authenticationSlice';
import { PartialDashboard, User } from 'api';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import LanguageSwitcher from './LanguageSwitcher';

const urlParams = new URLSearchParams(window.location.search);
const fullscreen = urlParams.get('fullscreen');

interface LayoutProps {
  user: User;
  dashboards?: PartialDashboard[];
  showIeWarning: Boolean;
}
const Layout: React.FunctionComponent<LayoutProps> = ({ user, dashboards, children, showIeWarning }) => {
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logout());
  const { t } = useTranslation();
  const marginTopStyle50px = {
    marginTop: "50px"
  }
  const marginTopStyle100px = {
    marginTop: "100px"
  }

  return (
    <div className={classNames(
      "mod-bg-1 mod-nav-link header-function-fixed nav-function-fixed mod-pace-custom mod-panel-icon mod-clean-page-bg mod-hide-info-card",
      {
        fullscreen: fullscreen,
      },
    )}>
      <div className="page-wrapper">
        <div className="page-inner">
          {/* BEGIN Left Aside */}
          <aside className="page-sidebar" style={showIeWarning ? marginTopStyle50px : {}}>
            <div className="page-logo">
              <Link to="/" className="page-logo-link press-scale-down d-flex align-items-center position-relative">
                <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="EHP-Dataservice 2.0" aria-roledescription="logo"/>
                <span className="page-logo-text mr-1">{t('EHP-Dataservice')} 2.0</span>
                <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"/>
              </Link>
            </div>
            {/* BEGIN PRIMARY NAVIGATION */}
            <nav id="js-primary-nav" className="primary-nav" role="navigation">
              <div className="nav-filter">
                <div className="position-relative">
                  <input type="text" id="nav_filter_input" placeholder="Filter menu" className="form-control"
                         tabIndex={0}/>
                  <a href="#" onClick={() => false} className="btn-primary btn-search-close js-waves-off"
                     data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                    <i className="fal fa-chevron-up"/>
                  </a>
                </div>
              </div>
              <div className="info-card">
                <div className="info-card-text">
                  <a href="#" className="d-flex align-items-center text-white">
                <span className="text-truncate text-truncate-sm d-inline-block">
                  {user.username}
                </span>
                  </a>
                  <span className="d-inline-block text-truncate text-truncate-sm">Oulu, Finland</span>
                </div>
                <a href="#" onClick={() => false} className="pull-trigger-btn" data-action="toggle"
                   data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
                  <i className="fal fa-angle-down"/>
                </a>
              </div>
              <ul id="js-nav-menu" className="nav-menu">
                {(dashboards && dashboards.length > 0) && (
                  <li className="active open">
                    <a href="#" title="Dashboards" data-filter-tags="dashboards">
                      <i className="fal fa-info-circle"/>
                      <span className="nav-link-text" data-i18n="nav.ehp">{t('Dashboards')}</span>
                    </a>
                    <ul>
                      {dashboards.map((dashboard) => (
                        <li key={`dashboard-${dashboard.id}`}>
                          <NavLink to={`/dashboards/${dashboard.id}`}>
                            <span className="nav-link-text">{dashboard.title}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                <li className="active open">
                  <a href="#" title="EHP" data-filter-tags="ehp">
                    <i className="fal fa-info-circle"/>
                    <span className="nav-link-text" data-i18n="nav.ehp">{t('Pages')}</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/dataloggers/">
                        <span className="nav-link-text">{t('Monitoring stations')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="filter-message js-filter-message bg-success-600"/>
            </nav>
            {/* END PRIMARY NAVIGATION */}
          </aside>
          {/* END Left Aside */}
          <div className="page-content-wrapper">
            {/* BEGIN Page Header */}
            <header className="page-header" role="banner" style={showIeWarning ? marginTopStyle50px : {}}>
              {/* we need this logo when user switches to nav-function-top */}
              <div className="page-logo">
                <a href="#" className="page-logo-link press-scale-down d-flex align-items-center position-relative"
                   data-toggle="modal" data-target="#modal-shortcut">
                  <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="EHP-Dataservice 2.0" aria-roledescription="logo"/>
                  <span className="page-logo-text mr-1">EHP-Dataservice 2.0</span>
                  <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"/>
                </a>
              </div>
              {/* DOC: mobile button appears during mobile width */}
              <div className="hidden-lg-up">
                <a href="#" className="header-btn btn press-scale-down" data-action="toggle"
                   data-class="mobile-nav-on">
                  <i className="ni ni-menu"/>
                </a>
              </div>
              <div className="ml-auto d-flex">
                {/* activate app search icon (mobile) */}
                <div className="hidden-sm-up">
                  <a href="#" className="header-icon" data-action="toggle" data-class="mobile-search-on"
                     data-focus="search-field" title="Search">
                    <i className="fal fa-search"/>
                  </a>
                </div>
                <div className="d-flex align-items-center">
                  <LanguageSwitcher />
                </div>
                {/* app user menu */}
                <div>
                  <a href="#" data-toggle="dropdown" title={user.username}
                     className="header-icon d-flex align-items-center justify-content-center ml-2">
                    <img src={`${process.env.PUBLIC_URL}/img/demo/avatars/avatar-m.png`} className="profile-image rounded-circle" alt={user.username}/>
                  </a>
                  <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                    <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                      <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                    <span className="mr-2">
                      <img src={`${process.env.PUBLIC_URL}/img/demo/avatars/avatar-m.png`} className="rounded-circle profile-image"
                           alt={user.username} />
                    </span>
                        <div className="info-card-text">
                          <div className="fs-lg text-truncate text-truncate-lg">{user.username}</div>
                          <span className="text-truncate text-truncate-md opacity-80">{user.username}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider m-0"/>
                    <a href="#" className="dropdown-item" data-action="app-fullscreen">
                      <span data-i18n="drpdwn.fullscreen">Fullscreen</span>
                      <i className="float-right text-muted fw-n">F11</i>
                    </a>
                    <a href="#" className="dropdown-item" data-action="app-print">
                      <span data-i18n="drpdwn.print">Print</span>
                      <i className="float-right text-muted fw-n">Ctrl + P</i>
                    </a>
                    <div className="dropdown-multilevel dropdown-multilevel-left">
                      <div className="dropdown-menu">
                        <a href="#?lang=fr" className="dropdown-item" data-action="lang" data-lang="fr">Français</a>
                        <a href="#?lang=en" className="dropdown-item active" data-action="lang" data-lang="en">English
                          (US)</a>
                        <a href="#?lang=es" className="dropdown-item" data-action="lang" data-lang="es">Español</a>
                        <a href="#?lang=ru" className="dropdown-item" data-action="lang" data-lang="ru">Русский
                          язык</a>
                        <a href="#?lang=jp" className="dropdown-item" data-action="lang" data-lang="jp">日本語</a>
                        <a href="#?lang=ch" className="dropdown-item" data-action="lang" data-lang="ch">中文</a>
                      </div>
                    </div>
                    <div className="dropdown-divider m-0"/>
                    <button className="btn-link dropdown-item fw-500 pt-3 pb-3" onClick={handleLogout}>
                      <span data-i18n="drpdwn.page-logout">Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* END Page Header */}
            {/* BEGIN Page Content */}
            {/* the #js-page-content id is needed for some plugins to initialize */}
            <div id="js-page-content" role="main" className="page-content" style={showIeWarning ? marginTopStyle100px : {}}>
              {children}
            </div>
            {/* this overlay is activated only when mobile menu is triggered */}
            <div className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"/>
            {/* END Page Content */}
            {/* BEGIN Page Footer */}
            <footer className="page-footer" role="contentinfo">
              <div className="d-flex align-items-center flex-1 text-muted">
                  <span className="hidden-md-down fw-700">2020 © EHP-Dataservice by&nbsp;<a
                    href="https://www.ehpenvironment.com" className="text-primary fw-500"
                    title="EHP Environment" target="_blank" rel="noopener noreferrer">EHP Environment</a></span>
              </div>
            </footer>
            {/* END Page Footer */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
