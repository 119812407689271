import * as React from 'react';
import classNames from 'classnames';

interface PanelProps {
  show?: boolean;
  id?: string;
}
const Panel: React.FunctionComponent<PanelProps> = ({
  children ,
  show,
  id,
}) => (
  <div id={id} className="panel">
    <div className={classNames(
      'panel-container',
      {
        'show': show === undefined || show,
      }
    )}>
      <div className="panel-content">
        {children}
      </div>
    </div>
  </div>
);
export default Panel;
