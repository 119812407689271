import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useTranslation } from 'react-i18next';

am4core.useTheme(am4themes_animated);

const InclinometerChart = ({ chartId, chartData, horizontalInclinometer, numberOfValues, soilRanges }: any) => {
  const xAxisMax = 10;
  let minGridDistance = 1;
  const maxValue = chartData ? Math.max(...chartData.map((data: any) => data.value ? Math.abs(data.value) : 0)) : xAxisMax;
  const { t } = useTranslation();

  if (chartData && chartData.length > 100) {
    minGridDistance = 10;
  }

  if (chartData && soilRanges) {
    let largestDataDepth = parseFloat(chartData[0]['depth']);
    let dataTimestamp1 = chartData[0]['timestamp1'];
    let dataTimestamp2 = chartData[0]['timestamp2'];
    const largestSoilRangeDepth = parseFloat(soilRanges[soilRanges.length - 1][0]);

    while (largestDataDepth > largestSoilRangeDepth) {
      largestDataDepth -= 1.0;
      chartData.unshift(
        {
          'category': String(largestDataDepth.toFixed(1)),
          'timestamp1': dataTimestamp1,
          'timestamp2': dataTimestamp2,
        }
      )
    }
  }

  useEffect(() => {
    const colorSet = new am4core.ColorSet();
    const depthFromSurface = chartData ? parseFloat(chartData[chartData.length-1]['depth']) : 0.0;

    colorSet.list = [
      am4core.color("#00A1D0"),
      am4core.color("#A8C600"),
      am4core.color("#FC7149")
    ];

    const createSeries = (valueNumber: any) => {
      let timestamp = '';
      if (chartData) {
        if (valueNumber === 1) {
          valueNumber = "";
          timestamp = chartData[chartData.length - 1].timestamp2;
        } else {
          timestamp = chartData[chartData.length - 1][`value${valueNumber}-timestamp`];
          timestamp = timestamp.split(" ")[0];
        }
        timestamp = new Date(timestamp)
          .toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' });
        timestamp = timestamp.split(" ")[0];
      }

      let series = chart.series.push(new am4charts.LineSeries());
      const seriesColor = am4core.color(colorSet.next().hex);
      series.name = timestamp;
      if (horizontalInclinometer) {
        series.dataFields.valueY = `value${valueNumber}`;
        series.dataFields.categoryX = "category";
        series.tooltipText = "{valueY.value}";
      } else {
        series.dataFields.valueX = `value${valueNumber}`;
        series.dataFields.categoryY = "category";
        series.tooltipText = "{valueX.value}";
      }
      series.strokeWidth = 3;
      series.stroke = seriesColor;
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = seriesColor;
      }

      // Bullets
      const bullet = new am4charts.CircleBullet();
      if (minGridDistance !== 10) {
        bullet.circle.strokeWidth = 6;
        bullet.circle.fill = am4core.color("#fff");
        bullet.properties.scale = 0.5;

        const bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1;

        series.bullets.push(bullet);
      }
    }

    const createRanges = (rangeValues: string[], index: number, depthAxis: any) => {
      // TODO: Get range color by range type/title
      const rangeColors = [
        "#B5ACA3",
        "#AA9075",
        "#9D723D",
        "#8A532C",
        "#653d21"
      ]
      const [rangeStartValue, rangeEndValue] = [parseFloat(rangeValues[0]), parseFloat(rangeValues[1])];
      const startDecimals: number = parseFloat((rangeStartValue % 1).toFixed(1));
      const startValue = (parseFloat(rangeStartValue.toFixed()) + depthFromSurface).toFixed(1);
      const endDecimals: number = parseFloat((rangeEndValue % 1).toFixed(1));
      const endValue = (parseFloat(rangeEndValue.toFixed()) + depthFromSurface).toFixed(1);

      const range = depthAxis.axisRanges.create();
      range.category = startValue.toString();
      range.endCategory = endValue.toString();

      Math.abs(startDecimals) < 0.5 ?
        range.locations.category = 0.5 - depthFromSurface - Math.abs(startDecimals)
        :
        range.locations.category = 1.5 - depthFromSurface - Math.abs(startDecimals);

      Math.abs(endDecimals) < 0.5 ?
        range.locations.endCategory = 0.5 - depthFromSurface - Math.abs(endDecimals)
        :
        range.locations.endCategory = 1.5 - depthFromSurface - Math.abs(endDecimals);

      range.axisFill.fill = am4core.color(rangeColors[index]);
      range.axisFill.fillOpacity = 0.4;
      range.grid.strokeOpacity = 0;
      range.label.text = rangeValues[2];
      range.label.inside = true;
    }

    let chart = am4core.create(chartId, am4charts.XYChart);
    // Add data
    chart.data = chartData;
    chart.numberFormatter.numberFormat = "####.00";

    let depthAxis = horizontalInclinometer ?
      chart.xAxes.push(new am4charts.CategoryAxis())
      :
      chart.yAxes.push(new am4charts.CategoryAxis());
    depthAxis.dataFields.category = "category";
    depthAxis.title.text = horizontalInclinometer ?
      'Etäisyys (m)'
      :
      `${t('Depth')} (m)`
    depthAxis.renderer.minGridDistance = minGridDistance;
    depthAxis.renderer.grid.template.disabled = true;
    if (horizontalInclinometer) depthAxis.renderer.minGridDistance = 150;

    if (soilRanges && chartData) {
      soilRanges.map((r: any, i: number) => createRanges(r, i, depthAxis))
    }

    let valueAxis = horizontalInclinometer ?
      chart.yAxes.push(new am4charts.ValueAxis())
      :
      chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.numberFormatter.numberFormat = "##' mm'";
    valueAxis.chart.paddingRight = 25;
    valueAxis.min = maxValue > xAxisMax ? -maxValue : -xAxisMax;
    valueAxis.max = maxValue > xAxisMax ? maxValue : xAxisMax;

    for (let i = 1; i <= numberOfValues; i++) {
      createSeries(i)
    }

    chart.legend = new am4charts.Legend();
    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    return () => {
      chart.dispose();
    };

  }, [chartData, chartId, maxValue, horizontalInclinometer, numberOfValues, t, soilRanges, minGridDistance]);
  return (
    <div id={chartId} style={{ height: "700px" }}></div>
  );
}

export default InclinometerChart;
