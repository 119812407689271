/*
 * Rerender contents when viewport changes changes
 */
import * as React from 'react';
import { useEffect } from 'react';

export interface RerendererProps {
  pollInterval?: number;
}
const Rerenderer: React.FC<RerendererProps> = ({ children , pollInterval = 1000}) => {
  const [ windowWidth, setWindowWidth ] = React.useState(window.innerWidth)
  const [ shouldRender, setShouldRender ] = React.useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(windowWidth === window.innerWidth) {
        return;
      }
      setWindowWidth(window.innerWidth);
      setShouldRender(false);
    }, pollInterval);
    return () => {
      clearInterval(intervalId);
    }
  }, [windowWidth, setWindowWidth, setShouldRender, pollInterval]);

  useEffect(() => {
    if(!shouldRender) {
      setShouldRender(true)
    }
  }, [shouldRender, setShouldRender])

  if (shouldRender) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return <></>;
  }
}
export default Rerenderer;
