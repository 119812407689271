import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import localeEn from 'date-fns/locale/en-US';
import localeFi from 'date-fns/locale/fi';
import localeSe from 'date-fns/locale/sv';

const DATEFORMAT = "d.M.yyyy H:mm:ss"
const TIMEFORMAT = "HH:mm"

const TIME_MIN = new Date();
TIME_MIN.setHours(0, 0, 0, 0);
const TIME_MAX = new Date();
TIME_MAX.setHours(23, 59, 59, 999);

registerLocale('en', localeEn);
registerLocale('fi', localeFi);
registerLocale('se', localeSe);

export interface DateTimeRangeProps {
  currentStart?: moment.MomentInput;
  currentEnd?: moment.MomentInput;
  onChangeStart?: (date: moment.Moment) => void;
  onChangeEnd?: (date: moment.Moment) => void;
  resetDataRange?: () => void;
}
const DateTimeRangeForm = (props: DateTimeRangeProps) => {
  const {
    currentStart,
    currentEnd,
    onChangeStart = () => { },
    onChangeEnd = () => { },
    resetDataRange
  } = props;
  const { t, i18n } = useTranslation();
  const currentStartMoment = currentStart ? moment(currentStart) : null;
  const currentEndMoment = currentEnd ? moment(currentEnd) : null;
  let restrictTimes = false;
  if (currentStartMoment && currentEndMoment && currentStartMoment.isSame(currentEndMoment, 'day')) {
    restrictTimes = true;
  }

  const handleChangeStart = (date: any) => {
    const newStart = moment(date);
    if (currentEndMoment && newStart.isAfter(currentEndMoment)) {
      console.error(`start ${newStart.format()} would be after end ${currentEndMoment.format()}, not setting`);
      return;
    }
    onChangeStart(newStart);
  }

  const handleChangeEnd = (date: any) => {
    const newEnd = moment(date);
    if (currentStartMoment && newEnd.isBefore(currentStartMoment)) {
      console.error(`end ${newEnd.format()} would be before start ${currentStartMoment.format()}, not setting`);
      return;
    }
    onChangeEnd(newEnd);
  }

  return (
    <Form inline>
      <Form.Label>{t('Start date')}&nbsp;</Form.Label>
      <Form.Control
        as={DatePicker}
        selected={currentStartMoment ? currentStartMoment.toDate() : undefined}
        maxDate={currentEndMoment ? currentEndMoment.toDate() : undefined}
        minTime={restrictTimes ? TIME_MIN : undefined}
        maxTime={(restrictTimes && currentEndMoment) ? currentEndMoment.toDate() : undefined}
        showTimeSelect
        dateFormat={DATEFORMAT}
        timeFormat={TIMEFORMAT}
        onChange={handleChangeStart}
        locale={i18n.language}
        timeCaption={t('Time')}
      />
      <span>{' '}&nbsp;{' '}</span>
      <Form.Label>{t('End date')}&nbsp;</Form.Label>
      <Form.Control
        as={DatePicker}
        selected={currentEndMoment ? currentEndMoment.toDate() : undefined}
        minDate={currentStartMoment ? currentStartMoment.toDate() : undefined}
        minTime={(restrictTimes && currentStartMoment) ? currentStartMoment.toDate() : undefined}
        maxTime={restrictTimes ? TIME_MAX : undefined}
        showTimeSelect
        dateFormat={DATEFORMAT}
        timeFormat={TIMEFORMAT}
        onChange={handleChangeEnd}
        locale={i18n.language}
        timeCaption={t('Time')}
      />

      {currentStart && currentEnd && (
        <Button
          className="ml-5"
          onClick={resetDataRange}
        >
          {t('Reset time period')}
        </Button>
      )}

    </Form>
  )
}

export default DateTimeRangeForm;
