import { combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from 'features/authentication/authenticationSlice';
import dataloggersReducer from 'features/dataloggerList/dataloggersSlice';
import chartsReducer from 'features/dataloggerCharts/chartsSlice';
import dashboardsReducer from 'features/dashboards/dashboardsSlice';


const rootReducer = combineReducers({
  authentication: authenticationReducer,
  dataloggers: dataloggersReducer,
  charts: chartsReducer,
  dashboards: dashboardsReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
