import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from 'features/authentication/authenticationSlice';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Subheader from '../components/layout/Subheader';

const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    // Action to clear local storage and cookies,
    // as well as redirect to the root page.
    dispatch(logout());
    setIsLoading(false);
  }, [dispatch, isLoading]);
  return (
    <>
      <Breadcrumbs />
      {isLoading && <Subheader>Logging out..</Subheader>}
      {!isLoading && <Redirect to='/' />}
    </>
  );
};
export default LogoutPage;
