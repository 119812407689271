import * as React from 'react';
import { useEffect } from 'react';
import Subheader from '../components/layout/Subheader';
import DataloggerLoader from '../features/dataloggerList/DataloggerLoader';
import { Datalogger, Datapoint, Unit } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { initCharts, selectUnitsWithDataByUniquename, UnitWithData } from '../features/dataloggerCharts/chartsSlice';
import DataChart from '../features/dataloggerCharts/DataChart';
import Panel from '../components/layout/Panel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Doughnut, Line } from 'react-chartjs-2';
import { color } from '../features/dataloggerCharts/legacyConfig';
import moment from 'moment-timezone';

import pillar from './pilari.png';


interface TiltDashboardProps {
  datalogger: Datalogger;
}
const TiltDashboard = ({ datalogger }: TiltDashboardProps) => {
  const dispatch = useDispatch();
  const unitsWithData = useSelector(selectUnitsWithDataByUniquename);

  useEffect(() => {
    dispatch(initCharts(datalogger, {
      startDateTimeFromEnd: {
        amount: 1,
        unit: 'days',
      }
    }));
  }, [dispatch, datalogger]);

  const uniquename = '1A';
  const unitWithData = unitsWithData[uniquename];
  if(!unitWithData) {
    return <div />;
  }

  const diagram = (uniquenameA: string, uniquenameB: string) => {
    const a = unitsWithData[uniquenameA];
    const b = unitsWithData[uniquenameB];
    return (
      <TiltDiagram
        a={a}
        b={b}
        key={`${uniquenameA}-${uniquenameB}`}
      />
    );
  };
  return (
    <Container fluid>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <Row>
            <Col>
              {diagram('1A', '1B')}
            </Col>
          </Row>
          <Row>
            <Col>
              {diagram('2A', '2B')}
            </Col>
          </Row>
        </Col>
        <Col lg={4} className="d-none d-lg-block d-xl-block">
          {/*
          <img src={pillar} style={{
            maxWidth: '100%',
          }}/>
          */}
          <Image src={pillar} fluid style={{
            transform: 'translateY(-40px)',
          }}/>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Row>
            <Col>
              {diagram('3A', '3B')}
            </Col>
          </Row>
          <Row>
            <Col>
              {diagram('4A', '4B')}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
interface TiltDiagramProps {
  a: UnitWithData;
  b: UnitWithData;
  showTiltChart?: boolean;
}
const TiltDiagram = ({ a, b, showTiltChart }: TiltDiagramProps) => {
  return (
    <Panel>
      <Container fluid>
        <Row>
          <Col sm={6}>
            <h3 className="text-center">{a.unit.name}</h3>
            <Gauge unit={a.unit} data={a.data} loading={a.loading} />
          </Col>
          <Col sm={6}>
            <h3 className="text-center">{b.unit.name}</h3>
            <Gauge unit={b.unit} data={b.data} loading={b.loading} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataChart items={[a, b]} />
            {showTiltChart &&
              <TiltChart />
            }
          </Col>
        </Row>
      </Container>
    </Panel>
  )
}


interface GaugeProps {
  unit: Unit;
  data: Datapoint[];
  loading?: boolean;
}
const Gauge = ({ unit, data, loading }: GaugeProps) => {
  const red = 'rgba(255,0,49,0.54)';
  const yellow = 'rgba(255,199,0,0.54)';
  const green = 'rgba(160,255,110,0.55)';
  const black = '#111111';
  const basicData = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
  const basicBackgroundColors = [
    red, red, red, red,
    yellow, yellow, yellow,
    green, green, green, green, green,
    yellow, yellow, yellow, yellow,
    red, red, red, red,
  ];
  const chartOptions = {
    circumference: 1 * Math.PI,
    rotation: 1 * Math.PI,
    cutoutPercentage: 70,
    borderWidth: 0,
  }
  const value = data[data.length - 1]?.value || 0.0;
  const backgroundColor = basicBackgroundColors.slice();
  if(!loading) {
    backgroundColor[Math.round((value + 90) / 10)] = black;
  }
  const chartData = {
    datasets: [
      {
        data: basicData,
        backgroundColor,
      }
    ]
  }
  const symbol = unit.symbol || '°';
  return (
    <div>
      <h2 className="text-center">{value.toFixed(2)} {symbol}</h2>
      <Doughnut data={chartData} options={chartOptions} />
    </div>
  )
};

const TiltChart = () => {
  const chartData = {
    datasets: [
      {
        label: 'Foo',
        fill: false,
        borderColor: color.primary._300,
        pointBackgroundColor: color.primary._500,
        pointBorderColor: color.primary._500,
        pointBorderWidth: 1,
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40].map((y, i) => ({
          y,
          t: (moment().subtract(i, 'minutes')),
        })),
      }
    ],
  };
  const chartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-1',
          type: 'time',
          time:
            {
              unit: 'minute',
            }
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: '',
          },
          gridLines: {
            display: true,
            color: "#f2f2f2"
          },
          ticks: {
            beginAtZero: true,
            fontSize: 11
          },
          id: 'y-1',
        },
      ],
    },
  };

  return (
    <Line data={chartData} options={chartOptions} />
  )
};

const TiltDemo2: React.FC = () => {
  const idcode = '50042';
  return (
    <DataloggerLoader idcode={idcode}>
      {(datalogger) => (
        <>
          <Subheader>{datalogger.name} [{datalogger.idcode}]</Subheader>
          <div>
            <TiltDashboard datalogger={datalogger} />
          </div>
        </>
      )}
    </DataloggerLoader>
  )
};
export default TiltDemo2;
