import Overlay from "ol/Overlay";
import * as proj from "ol/proj";
import Feature from "ol/Feature";
import * as geom from "ol/geom";
import LayerVector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OlMap from "ol/Map.js";
import Text from 'ol/style/Text.js';
import { TFunction } from 'i18next';
import { Datalogger, LayerSource } from '../../api';
import MapPinIcon from '../../img/ehp-map-pin.png';
import CompassFrame from '../../img/compass-frame.png';
import CompassNeedle from '../../img/compass-needle.png';
import MapAlertIcon from '../../img/alert-map-pin.png';
import { WindData } from './DataloggerMap';
import { Circle, Fill, RegularShape, Stroke } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';

export const createMarkers = (dataloggers: Datalogger[], map: OlMap, t: TFunction) => {
  const labels: Overlay[] = [];
  let lonLat;

  dataloggers.forEach((logger) => {
    if (logger.lon && logger.lat) {
      lonLat = [logger.lon, logger.lat];
    } else {
      lonLat = [0, 0]
    }

    // Create marker
    const iconFeature = new Feature({
      geometry: new geom.Point(proj.fromLonLat(lonLat)),
      name: logger.name,
      id: logger.id,
    });

    if (lonLat[0] !== 0 && lonLat[1] !== 0) {
      // Map pin icon layer
      map.addLayer(new LayerVector({
        source: new SourceVector({
          features: [iconFeature]
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.48, 34],
            crossOrigin: 'anonymous',
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: logger.has_triggered_alerts ? MapAlertIcon : MapPinIcon
          })
        })
      }));
    }

    // Create label
    const container = document.createElement("div");
    container.classList.add("map-label-div");

    const titleDiv = document.createElement("div");
    const titleText = document.createTextNode(logger.name);
    titleDiv.appendChild(titleText);

    const infoA = document.createElement("a");
    const linkText = document.createTextNode(t("Info"));
    infoA.appendChild(linkText);
    infoA.href = `${process.env.PUBLIC_URL}/dataloggers/${logger.idcode}`;

    container.appendChild(titleDiv);
    if (logger.has_triggered_alerts) {
      const alertDiv = document.createElement('div');
      alertDiv.style.fontSize = '0.9em';
      alertDiv.appendChild(
        document.createTextNode(t('Has currently active alerts'))
      );
      container.appendChild(alertDiv);
    }
    titleDiv.appendChild(titleText);

    container.appendChild(infoA);

    const label = new Overlay({
      position: undefined, // setting to undefined position means hiding it
      element: container,
      stopEvent: false,
    });
    labels.push(label);
    map.addOverlay(label);

    // ugly hack to associate feature with label
    (iconFeature as any).label = label;
  });

  // Map interaction
  map.on("singleclick", function (e) {
    e.preventDefault()
    for (let label of labels) {
      label.setPosition(undefined);
    }

    let featureCount = 0;
    map.forEachFeatureAtPixel(
      e.pixel,
      function (feature: any) {
        if (feature && featureCount === 0 && feature.label) {
          feature.label.setPosition(e.coordinate);
          featureCount++;
        }
      }
    )
  });

  map.on('pointermove', function (e) {
    e.preventDefault();
    map.getTargetElement().style.cursor =
      map.hasFeatureAtPixel(e.pixel) ? 'pointer' : '';
  });
}

const createSourceLayer = (sources: LayerSource[], values: {[key: number]: number}, map: any) => {
  const source = sources[0]
  let colorIndex = 0;
  // @ts-ignore
  for (const s of sources) {
    // Get values for source
    const sourceValues = []
    for (const [key, value] of Object.entries(values)) {
      if (s.ids.includes(Number(key))) {
        sourceValues.push(value)
      }
    }

    if (sourceValues.length > 0) {
      const [maxValue , minValue] = [Math.max(...sourceValues), Math.min(...sourceValues)];
      const value = Math.abs(minValue) > maxValue ? minValue : maxValue;

      if (colorIndex <= 1 && value <= s.limits['green'][1] && value >= s.limits['green'][0]) {
        colorIndex = 1
      } else if (colorIndex <= 2 && value <= s.limits['yellow'][1] && value >= s.limits['yellow'][0]) {
        colorIndex = 2
      } else if (colorIndex <= 3 && (value > s.limits['yellow'][1] || value < s.limits['yellow'][0])) {
        colorIndex = 3
      }
    }
  }

  const colors = [
    [120, 120, 120], // Grey
    [0, 220, 0], // Green
    [220, 220, 0], // Yellow
    [220, 0, 0], // Red
  ]

  let color = colors[colorIndex]
  const stroke = new Stroke({color: [255, 255, 255], width: 1})
  const titleText = new Text({
    text: source.title,
    scale: 2,
  })

  const styles = {
    'circle': new Style({
      image: new Circle({
        radius: 15,
        fill: new Fill({ color: color }),
        stroke: stroke,
      }),
      text: titleText,
    }),
    'square': new Style({
      image: new RegularShape({
        fill: new Fill({ color: color }),
        stroke: stroke,
        points: 4,
        radius: 18,
        angle: Math.PI / 4,
      }),
      text: titleText,
    }),
    'triangle': new Style({
      image: new RegularShape({
        fill: new Fill({ color: color }),
        stroke: stroke,
        points: 3,
        radius: 23,
        angle: 90,
      }),
      text: titleText,
    })
  }

  const iconFeature = new Feature({
    geometry: new geom.Point(proj.fromLonLat(source.coordinates)),
  });

  map.addLayer(new LayerVector({
    source: new SourceVector({
      features: [iconFeature]
    }),
    // @ts-ignore
    style: styles[source.style],
  }));
}

export const addSourceLayers = (sources: LayerSource[], sourceValues: {[key: number]: number}, map: OlMap) => {
  if (sources.length > 0) {
    const sourcesByLayerId: {[key: number]: LayerSource[]} = {}
    for (const source of sources) {
      if (sourcesByLayerId[source.layer_id]) {
        sourcesByLayerId[source.layer_id].push(source)
      } else {
        sourcesByLayerId[source.layer_id] = [source]
      }
    }

    // @ts-ignore
    Object.keys(sourcesByLayerId).forEach(key => {
      // @ts-ignore
      createSourceLayer(sourcesByLayerId[key], sourceValues, map)
    })
  }
}

const textStyleFunction = (text : string) => {
  return [
    new Style({
      text: new Text({
        font: '18px Calibri,sans-serif',
        fill: new Fill({color: '#000'}),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text + ' m/s',
        offsetX: 80,
        offsetY: 80,
      }),
    })
  ]
}

export const addWindCompassLayer = (logger : Datalogger, windData : WindData, map : OlMap) => {
  let lonLat = [0, 0]
  if (logger.lon && logger.lat) {
    lonLat = [logger.lon, logger.lat];
  }
  const angle = ((windData.direction - 180) * Math.PI) / 180;
  const needleOpacity = windData.speed < 0.1 ? 0.25 : 1;

  const iconFeature = new Feature({
    geometry: new geom.Point(proj.fromLonLat(lonLat)),
  });

  // Compass frame layer
  map.addLayer(new LayerVector({
    source: new SourceVector({
      features: [iconFeature]
    }),
    style: new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: CompassFrame
      })
    })
  }));

  // Compass needle layer
  map.addLayer(new LayerVector({
    source: new SourceVector({
      features: [iconFeature]
    }),
    style: new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: CompassNeedle,
        rotation: angle,
        opacity: needleOpacity,
      })
    })
  }));

  // Wind speed text layer
  const textFeature = new Feature(
    new Point(fromLonLat(lonLat))
  );
  map.addLayer(
    new VectorLayer({
      source: new VectorSource({
        features: [textFeature]
      }),
      style: textStyleFunction(String(windData.speed?.toFixed(2)))
    })
  )

}


export const addImageLayer = (map : OlMap) => {
  let lonLat = [24.030460077972798, 61.264757566155126]

  const iconFeature = new Feature({
    geometry: new geom.Point(proj.fromLonLat(lonLat)),
  });

  map.addLayer(new LayerVector({
    source: new SourceVector({
      features: [iconFeature]
    }),
    style: new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: '../../xy-axis.png',
        rotation: -0.32,
        scale: 0.5,
        displacement: [-40, -20],
      })
    })
  }));
}
