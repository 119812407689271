import { Datapoint, TerseDatapoint } from './index';

export const toTerseDatapoints = (data: Datapoint[] | TerseDatapoint[]): TerseDatapoint[] => {
  if (data.length === 0) {
    return [];
  }
  const first = data[0];
  if((first as Datapoint).timestamp !== undefined) {
    return (data as Datapoint[]).map(d => [d.timestamp, d.value]);
  }
  return data as TerseDatapoint[];
}
