import React, { useEffect, useRef, useState } from "react";
import OlMap from "ol/Map.js";
import View from "ol/View.js";
import * as proj from 'ol/proj';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import MapLayer from "./mapLayer";
import { addImageLayer, addSourceLayers, addWindCompassLayer, createMarkers } from "./mapUtils";
import { Datalogger, LayerSource } from '../../api';
import './map.css';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import { useTranslation } from 'react-i18next';
import { isIE } from 'compat';
import {defaults} from 'ol/interaction/defaults';

const LayerSwitcher = require('ol-layerswitcher');

export interface WindData {
  direction: number;
  speed: number;
}
interface DataloggerMapProps {
  dataloggers: Datalogger[];
  zoom?: number;
  coordinates?: number[];
  windData?: WindData;
  layerSources?: LayerSource[] | [];
  sourceValues?: {[key: number]: number};
  center?: number[];
}

const DataloggerMapInner = (props: DataloggerMapProps) => {
  let {
    dataloggers,
    zoom = 5,
    coordinates = [0, 0],
    windData,
    layerSources,
    sourceValues,
    center
  } = props
  let mapContainerStyle = layerSources ? {height: "500px"} : {};

  if (center) {
    coordinates = center
  } else if (dataloggers.length === 1 && dataloggers[0].lon && dataloggers[0].lat) {
    coordinates = [dataloggers[0].lon, dataloggers[0].lat]
  }
  const [windCompassCreated, setWindCompassCreated] = useState(false);
  const [map, setMap] = useState();
  const mounted = useRef(null);
  const { t } = useTranslation()

  if (coordinates[0] === 0 || coordinates[1] === 0) {
    center = proj.fromLonLat([25.4, 65.0]);
    zoom = 4;
  } else {
    center = proj.fromLonLat(coordinates);
  }

  // @ts-ignore
  let controls = null;
  let interactions = {}
  if (layerSources) {
    controls = [
      new LayerSwitcher(),
      new FullScreen()
    ]
    interactions = {
      mouseWheelZoom: false,
      dragPan: false,
      doubleClickZoom: false,
    }
  } else {
    controls = defaultControls().extend([
      new LayerSwitcher(),
      new FullScreen()
    ]);
  }

  useEffect(() => {
    if (!map) {
      const newMap = new OlMap({
        target: mounted.current || undefined,
        layers: MapLayer,
        view: new View({
          projection: 'EPSG:3857',
          center: center,
          zoom: zoom
        }),
        // @ts-ignore
        controls: controls,
        interactions: defaults(interactions)
      })
      if (dataloggers.length > -1 && !windData) {
        createMarkers(dataloggers, newMap, t);
      }
      if (layerSources) {
        for (const l of newMap.getAllLayers()) {
          if (l.get('title') === 'Ortoilmakuva') {
            l.setVisible(true);
          } else {
            l.setVisible(false);
          }
        }
      }
      setMap(newMap)
    } else {
      // TODO: Fix and enable this if needed
      if (windData && !windCompassCreated && !true) {
        addWindCompassLayer(dataloggers[0], windData, map);
        setWindCompassCreated(true);
      }
      if (layerSources && sourceValues) {
        addSourceLayers(layerSources, sourceValues, map);
        // TODO: This is only for the Valkeakoski Isosilta map. I'll refactor this later :)
        addImageLayer(map);
      }
    }

  }, [map, dataloggers, center, zoom, t, windData, windCompassCreated, layerSources, sourceValues, controls, interactions]);

  return (
    <div className="map-container" style={mapContainerStyle} ref={mounted} />
  );
}


const DataloggerMap = (props: DataloggerMapProps) => {
  const { t } = useTranslation()

  if (isIE) {
    return <div><small className="text-muted">({t('Maps are not supported on Internet Explorer')})</small></div>;
  }
  return <DataloggerMapInner {...props} />;
}
export default DataloggerMap;
