import * as React from 'react';

const Subheader: React.FC = ({ children }) => (
  <div className="subheader">
    <h1 className="subheader-title">
      <i className="subheader-icon fal fa-chart-area" /> {children}
    </h1>
  </div>
);
export default Subheader;
