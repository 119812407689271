import * as React from 'react';
import {
  API_BASE_URL,
  GaugeLineWidget,
  ImageWidget,
  MediaWidget,
  TerseExportResponse,
  Widget,
  DashboardDataResponse,
  InfoBoxWidget,
  AlertWidget,
  TerseDatapoint,
} from 'api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Gauge from '../../components/charts/Gauge';
import LineChart from '../../components/charts/LineChart';
import InclinometerChart from '../../components/charts/InclinometerChart'
import TemperatureChart from '../../components/charts/TemperatureChart'
import InclinometerXYChart from '../../components/charts/InclinometerXYChart'
import WindRose from '../../components/charts/WindRose';
import ColumnChart from '../../components/charts/ColumnChart';
import ConvergenceChart from '../../components/charts/ConvergenceChart';
import Image from 'react-bootstrap/Image';
import Rerenderer from '../../components/utils/Rerenderer';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Alert from 'react-bootstrap/Alert';
import Moment from 'react-moment';
import ExcelExport from '../../components/utils/excelExport';
import { useEffect, useState } from 'react';
import DataloggerMap, { WindData } from '../dataloggerMap/DataloggerMap';

const timestampTohDate = (timestamp: any) => {
  const dateArr = timestamp.split("T").shift().split("-");
  return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`
}

// Return prism data for map layer sources
const getPrismData = (
  data: TerseDatapoint[],
  sourceId: number,
  firstValuesByUnitId: { [key: number]: number },
  sourceSymbol: string,
): TerseDatapoint[] => {
  let prismData: TerseDatapoint[] = []
  const multiplier = sourceSymbol === 'mm' ? 1000 : 1
  if (data && data.length > 0) {
    const firstValue: number = firstValuesByUnitId ? firstValuesByUnitId[sourceId] * multiplier : 0
    prismData = data.map(d => [d[0], d[1] * multiplier - firstValue])
  }

  return prismData
}

export interface IDepth {
  title: string;
  key: number;
  axisTitleKey: number;
}

export interface WidgetDisplayProps {
  widget: Widget;
  data: DashboardDataResponse;
  number_of_decimals?: number;
  timeZone?: string;
  dataComparison?: boolean;
  dashboardTitle?: string;
  dashboardId?: string;
  resetComparisonData?: boolean;
  soilRanges?: any[]
}
const WidgetDisplay = (props: WidgetDisplayProps) => {
  const {
    widget,
    data,
    number_of_decimals,
    timeZone,
    dataComparison,
    dashboardTitle,
    dashboardId,
    resetComparisonData,
    soilRanges
  } = props;

  const windData : WindData = {
    direction: 0,
    speed: 0
  }

  if (widget.type === 'map' && data.meta.wind_data) {
    windData.direction = data.meta.wind_data.direction
    windData.speed = data.meta.wind_data.speed
  }

  // Map layer source values
  const sourceValues: {[key: number]: number} = {}
  if (widget.type === 'map' && widget.layer_sources) {
    for (const source of widget.layer_sources) {
      for (const id of source.ids) {
        const firstValuesByUnitId = data.meta.firstValuesByUnitId
        const sourceData = data.units[id]
        if (sourceData && sourceData.length > 0 && firstValuesByUnitId) {
          const prismData = getPrismData(sourceData, id, firstValuesByUnitId, source.symbol)
          const sourceValue = Number(prismData[prismData.length - 1][1].toFixed(3))
          sourceValues[id] = sourceValue;
        }
      }
    }
  }

  const largestDifferenceAfterInstallation = data.meta.largestDifferenceAfterInstallation ? data.meta.largestDifferenceAfterInstallation : { x: 0, y: 0 };
  const dayDifference = data.meta.day_difference[widget.id] ? data.meta.day_difference[widget.id] : 0;
  const firstValueDate = data.meta.firstValueDate ? data.meta.firstValueDate : { x: 0, y: 0 };
  const [selectedDepthIndex, setSelectedDepthIndex] = React.useState(0);
  const isConvergence = widget.data.extraProps?.gauge?.convergence;

  let depths: IDepth[] = [];
  if (['gauge-line', 'inclinometer-xy-chart'].includes(widget.type)) {
    if (data.widgets && data.widgets['w-1']) {
      if (widget.data.extraProps?.gauge?.widget_type === 'temperature-line') {
        if (widget.data.extraProps?.gauge.depths) {
          depths = widget.data.extraProps?.gauge.depths.map(
            (d: number, i: number) => ({ title: d*-1 + ' cm', key: i, axisTitleKey: d })
          )
        } else {
          data.widgets['w-1'][0].forEach((e: any, index: number) => {
            if (typeof (e.category) === 'number') {
              depths.push({ title: e.category + ' cm', key: index, axisTitleKey: Math.abs(e.category) });
            }
          })
          depths.reverse();
          depths = depths.map((d, i) => ({ ...d, key: i }))
        }
      } else {
        data.widgets['w-1'].forEach((e: any, index: number) => {
          if (e.category) depths.push({ title: `${e.category} ${!isConvergence ? "m" : ""}`, key: index, axisTitleKey: index });
        })
        depths.reverse();
        depths = depths.map((d, i) => ({ ...d, key: i }))
      }
    }
  }
  const selectedDepth = depths[selectedDepthIndex];

  return (
    <Rerenderer>
      {widget.type === 'map' ? (
        <DataloggerMap
          dataloggers={[]}
          zoom={widget.zoom ? widget.zoom : 9}
          windData={windData}
          layerSources={widget.layer_sources}
          sourceValues={sourceValues}
          coordinates={widget.center}
        />
      ) : widget.type === 'gauge-line' ? (
        <GaugeLineWidgetDisplay
          widget={widget}
          data={data}
          number_of_decimals={number_of_decimals}
          timeZone={timeZone}
          depths={depths}
          selectedDepth={selectedDepth}
          setSelectedDepth={setSelectedDepthIndex}
          dashboardId={dashboardId}
          startDate={data.meta.start}
          endDate={data.meta.end}
          dashboardTitle={dashboardTitle}
          firstValuesByUnitId={data.meta.firstValuesByUnitId}
        />
      ) : (widget.type === 'inclinometer-line') ? (
        <InclinometerWidgetDisplay widget={widget} data={data.widgets[widget.id]}
          largestDifferenceAfterInstallation={largestDifferenceAfterInstallation}
          firstValueDate={firstValueDate} startDate={data.meta.start} endDate={data.meta.end}
          dataComparison={dataComparison} resetComparisonData={resetComparisonData}
          dayDifference={dayDifference} dashboardTitle={dashboardTitle || ""} dashboardId={dashboardId}
          soilRanges={soilRanges}
        />
        // @ts-ignore
      ) : (widget.type === 'convergence-line') ? (
        <ConvergenceWidgetDisplay widget={widget} data={data.widgets[widget.id]}
          startDate={data.meta.start} endDate={data.meta.end}
          dashboardTitle={dashboardTitle || ""} dashboardId={dashboardId}
        />
      ) : (widget.type === 'temperature-line') ? (
        <TemperatureWidgetDisplay widget={widget} data={data.widgets[widget.id] ? data.widgets[widget.id][0] : []} airTempData={data.widgets[widget.id] ? data.widgets[widget.id][1] : {}}
          firstValueDate={firstValueDate}
        />
      ) : (widget.type === 'image') ? (
        <ImageWidgetDisplay widget={widget} />
      ) : (widget.type === 'media') ? (
        <MediaWidgetDisplay widget={widget} />
      ) : (widget.type === 'inclinometer-xy-chart') ? (
        <InclinometerXyWidgetDisplay widget={widget} data={data} depths={depths} selectedDepth={selectedDepth} setSelectedDepth={setSelectedDepthIndex} />
      ) : (widget.type === 'wind-rose') ? (
        <WindRoseWidgetDisplay widget={widget} data={data} bgImage={widget.background_image} dirLan={widget.direction_lan ? widget.direction_lan : 'en'} />
      ) : (widget.type === 'column-chart') ? (
        <ColumnChartWidgetDisplay widget={widget} data={data} />
      ) : (widget.type === 'info-box') ? (
        <InfoBoxWidgetDisplay widget={widget} />
      ) : (widget.type === 'alert') ? (
        <AlertWidgetDisplay widget={widget} />
      ) : (<pre>{JSON.stringify(widget, null, 2)}</pre>)}
    </Rerenderer>
  )
}
export default WidgetDisplay;

export interface ColumnChartWidgetDisplayProps {
  widget: Widget;
  data: any;
}

export const ColumnChartWidgetDisplay = ({ widget, data}: ColumnChartWidgetDisplayProps) => {
  const columnChart = <ColumnChart data={data} chartId={widget.id} widget={widget} />;

  return (
    <Container fluid>
      <Col>
        <Row>
          {columnChart}
        </Row>
      </Col>
    </Container>
  )
}

export interface WindRoseWidgetDisplayProps {
  widget: Widget;
  data: any;
  bgImage?: string;
  dirLan: string | 'en';
}

export const WindRoseWidgetDisplay = ({widget, data, bgImage, dirLan}: WindRoseWidgetDisplayProps) => {
  const windDirUnit = widget.data.sources.find((s: { symbol: string; }) => s.symbol === '°');
  const windRose =
    <WindRose data={data} chartId={widget.id} windDirUnitId={windDirUnit.id} bgImage={bgImage} dirLan={dirLan} />;

  return (
    <Container fluid>
      <Col>
        <Row>
          {windRose}
        </Row>
      </Col>
    </Container>
  )
}

export interface InclinometerXyWidgetDisplayProps {
  widget: Widget;
  data: DashboardDataResponse;
  selectedDepth: IDepth;
  setSelectedDepth: React.Dispatch<number>;
  depths: IDepth[];
}
export const InclinometerXyWidgetDisplay = ({ widget, data, selectedDepth, setSelectedDepth, depths }: InclinometerXyWidgetDisplayProps) => {
  const map_image = widget.data['map_image'];
  const axisTitles = widget.data['axis_titles'];
  const reversedAxes = widget.data['reversed_axes'];
  const totalShiftGauge = widget.data['total_shift_gauge'];
  const gaugeProps = widget.data?.extraProps?.gauge || {};
  let totalShiftData = null;
  const { t } = useTranslation();

  if (totalShiftGauge && data.meta.totalShiftValue) {
    totalShiftData = [["", data.meta.totalShiftValue]];
  }

  let selectDepthButton = null;
  if (selectedDepth) {
    selectDepthButton =
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedDepth.title}
          className="float-right mr-2"
          size="sm"
        >
          {depths.map((depth) =>
            <Dropdown.Item
              onClick={() => setSelectedDepth(depth.key)}
              key={depth.title}
            >
              {depth.title}
            </Dropdown.Item>
          )}
        </DropdownButton>
        <Form.Label column sm="1" className="float-right mr-2">
          {t('Depth')}:
        </Form.Label>
      </>
  }

  const dataToChart = getInclinometerChartData(data.widgets[widget.id]?.datasets, selectedDepth);

  const inclinometerXYChart = <InclinometerXYChart
    chartData={dataToChart}
    imageFilename={map_image}
    chartId={widget.id}
    axisTitles={axisTitles}
    reversedAxes={reversedAxes}
    totalShiftGauge={totalShiftGauge}
    totalShiftData={totalShiftData}
    gaugeProps={gaugeProps}
  />;

  if (dataToChart) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="text-center pb-5">{t(widget.title)} ({selectedDepth ? selectedDepth.title : null})</h2>
            {inclinometerXYChart}
            <div className="text-right">
              {selectDepthButton}
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return null;
  }
}

export interface InclinometerWidgetDisplayProps {
  widget: Widget;
  data: any;
  largestDifferenceAfterInstallation: { x: number, y: number };
  firstValueDate: { x: number, y: number };
  startDate: any;
  endDate: any;
  dataComparison?: boolean;
  dayDifference?: number;
  dashboardTitle: string;
  dashboardId?: string;
  resetComparisonData?: any;
  soilRanges?: any[];
}
export const InclinometerWidgetDisplay = (props: InclinometerWidgetDisplayProps) => {
  const [filteredChartData, setFilteredChartData] = useState(null);
  const [numberOfValues, setNumberOfValues] = useState(1)
  const extraProps = props.widget.data?.extraProps || {};
  const horizontalInclinometer = extraProps.horizontalInclinometer || false;
  const firstTimestamp = (props.data && props.data[0]) ? props.data[0].timestamp1 : null;
  const { t } = useTranslation();
  let { startDate, endDate, dataComparison, resetComparisonData, soilRanges } = props;
  startDate = timestampTohDate(startDate)
  endDate = timestampTohDate(endDate)

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      const tempData = [...props.data];
      tempData.pop();
      if (dataComparison && setFilteredChartData && filteredChartData && !resetComparisonData) {
        // @ts-ignore
        const dataLenDiff = filteredChartData.length - tempData.length;
        let valueTitle = 'value';
        if (numberOfValues + 1 > 1) {
          valueTitle = `value${numberOfValues + 1}`
        }
        setFilteredChartData(
          // @ts-ignore
          filteredChartData.map((fd, i) => {
            if (tempData[i-dataLenDiff]) {
              return {
                ...fd,
                [valueTitle]: tempData[i-dataLenDiff].value,
                [`${valueTitle}-timestamp`]: tempData[i-dataLenDiff].timestamp2
              }
            }
            else {
              return {
                ...fd,
              }
            }
          })
        )
        setNumberOfValues(n => n + 1);
      } else {
        setNumberOfValues(1)
        // @ts-ignore
        setFilteredChartData(tempData);
      }
    }
    // eslint-disable-next-line
  }, [props.data, setFilteredChartData])

  const inclinometerChart =
    <InclinometerChart
      chartData={filteredChartData}
      chartId={props.widget.id}
      horizontalInclinometer={horizontalInclinometer}
      numberOfValues={numberOfValues}
      soilRanges={soilRanges}
    />;

  let largestDifference = 0;
  let largestDifferenceAfterInstallation = 0;
  let dayDifferenceGauge = props.widget.data.extraProps.day_difference_gauge
    ? props.widget.data.extraProps.day_difference_gauge : false;
  let timescaleDifferenceGauge = props.widget.data.extraProps.timescale_difference_gauge
    ? props.widget.data.extraProps.timescale_difference_gauge : false;
  if (props.data?.length > 0) {
    largestDifference = props.data[props.data.length - 1].largest_difference;
    if (['Y Suunta', 'Y Direction'].includes(props.widget.title)) {
      largestDifferenceAfterInstallation = props.largestDifferenceAfterInstallation.y;
    } else {
      largestDifferenceAfterInstallation = props.largestDifferenceAfterInstallation.x
    }
  }
  const dayDifferenceLimits = props.widget.data.extraProps.day_difference_limits
    ? props.widget.data.extraProps.day_difference_limits : null;

  const gaugeProps = extraProps.gauge || {};
  const referenceDate = extraProps.reference_date || "asennukseen";
  const gaugeTitle = timescaleDifferenceGauge ?
    `${t('Largest change in profile after')} ${referenceDate}`
    :
    t('Profile change');

  const gaugeShiftAfterInstallation =
    <Gauge
      title={gaugeTitle}
      symbol="mm"
      data={[["", largestDifferenceAfterInstallation]]}
      loading={!props.data}
      number_of_decimals={2}
      middle={0}
      green={10}
      yellow={20}
      red={30}
      needle_in_middle={false}
      {...gaugeProps}
    />

  const gaugeShiftInSelectedTimescale =
    <Gauge
      title={t('Largest change in profile in the selected time period')}
      subtitle={`(${startDate} - ${endDate})`}
      symbol="mm"
      data={[["", largestDifference]]}
      loading={!props.data}
      number_of_decimals={2}
      middle={0}
      green={10}
      yellow={20}
      red={30}
      needle_in_middle={false}
      {...gaugeProps}
    />

  const dayShiftGreen = dayDifferenceLimits?.green ? dayDifferenceLimits.green : gaugeProps.green;
  const dayShiftRed = dayDifferenceLimits?.red ? dayDifferenceLimits.red : gaugeProps.red;
  const dayShiftYellow = dayDifferenceLimits?.yellow ? dayDifferenceLimits.yellow : gaugeProps.yello;

  const gaugeShiftIn24h =
    <Gauge
      title="24h Siirtymä"
      symbol="mm"
      data={[["", props.dayDifference]]}
      loading={!props.dayDifference}
      number_of_decimals={2}
      middle={0}
      needle_in_middle={false}
      {...gaugeProps}
      grayscale_gauge={gaugeProps.day_difference_grayscale_gauge}
      green={dayShiftGreen}
      red={dayShiftRed}
      yellow={dayShiftYellow}
      max_segment_labels={gaugeProps.day_difference_max_segment_labels}
    />

  return (
    <Container fluid>
      {props.widget.title && (
        <div className="text-center">
          <h2 className="text-center">{t(props.widget.title)}</h2>
          <p>{t('Reference point')}: {firstTimestamp ? <Moment format="DD.MM.YYYY HH:mm:ss">{firstTimestamp}</Moment> : ""}</p>
        </div>
      )}
      <Row>
        <Col>
          {inclinometerChart}
          <ExcelExport
            widget={props.widget}
            dashboardTitle={props.dashboardTitle}
            dashboardId={props.dashboardId}
          />
        </Col>
      </Row>
      <Row className="align-items-end mt-3">
        <Col>
          {gaugeShiftAfterInstallation}
        </Col>
        {timescaleDifferenceGauge && (
          <Col>
            {gaugeShiftInSelectedTimescale}
          </Col>
        )}
        {dayDifferenceGauge && (
          <Col>
            {gaugeShiftIn24h}
          </Col>
        )}
      </Row>
    </Container>
  )
}

export interface ConvergenceWidgetDisplayProps {
  widget: Widget;
  data: any;
  startDate: String;
  endDate: String;
  dashboardTitle?: string;
  dashboardId?: string;
}

export const ConvergenceWidgetDisplay = (props: ConvergenceWidgetDisplayProps) => {
  let largestDifference = 0
  let firstMeasurementDate = null;
  if (props.data?.length > 0) {
    largestDifference = props.data[props.data.length - 1].largest_difference;
    const firstDataDate = new Date(props.data[0].timestamp1)
    firstMeasurementDate = `${firstDataDate.getDate()}.${firstDataDate.getMonth() + 1}.${firstDataDate.getFullYear()}`
  }


  const convergenceChart =
    <ConvergenceChart
      chartData={props.data}
      chartId={props.widget.id}
      // @ts-ignore
      inverseXAxis={props.widget.inverse_x_axis}
    />;

    const gaugeShiftInSelectedTimescale =
    <Gauge
      title={`Profiilin muutos verrattuna ${firstMeasurementDate}`}
      symbol="mm"
      data={[["", largestDifference]]}
      loading={!props.data}
      number_of_decimals={2}
      middle={0}
      green={20}
      yellow={40}
      red={60}
      needle_in_middle={false}
      grayscale_gauge={true}
    />

  return (
    <Container fluid>
      <Row>
        <Col>
          {convergenceChart}
          <ExcelExport
            widget={props.widget}
            dashboardTitle={props.dashboardTitle}
            dashboardId={props.dashboardId}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3} />
        <Col lg={6} className="mt-5">
          {gaugeShiftInSelectedTimescale}
        </Col>
        <Col lg={3} />
      </Row>
    </Container>
  )
}

export interface TemperatureWidgetDisplayProps {
  widget: Widget;
  data: any;
  firstValueDate: { x: number, y: number };
  airTempData: any;
}
export const TemperatureWidgetDisplay = (props: TemperatureWidgetDisplayProps) => {
  const airTemperature = props.airTempData.value;

  const temperatureChart =
    <TemperatureChart
      chartData={props.data}
      chartId={props.widget.id}
    />;

  return (
    <Container fluid>
      {props.widget.title && (
        <div className="text-center">
          <h2 className="text-center">{props.widget.title}</h2>
        </div>
      )}
      <div className="text-center">
        Ilma: {airTemperature} °C
        </div>
      <Row>
        <Col>
          {temperatureChart}
        </Col>
      </Row>
    </Container>
  )
}

export interface ImageWidgetDisplayProps {
  widget: ImageWidget;
}
export const ImageWidgetDisplay = ({ widget }: ImageWidgetDisplayProps) => {
  return (
    <Image
      fluid
      src={widget.data.src}
    />
  )
}

export interface InfoBoxWidgetDisplayProps {
  widget: InfoBoxWidget;
}
export const InfoBoxWidgetDisplay = ({ widget }: InfoBoxWidgetDisplayProps) => {
  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          <Image
            fluid
            width={250}
            src={`${API_BASE_URL}/api/ui/v1/media/${widget.data.client}/${widget.data.filename}`}
          />
        </Col>
        <Col md={9}>
          {widget.data.text.map((t: any, i: number) => {
            return (
              <Row key={`infoRow-${i}`}>
                <Col className="md-2 p-2 border-left border-top border-bottom">
                  {t[0]}
                </Col>
                <Col className="md-2 p-2 border-right border-top border-bottom">
                  {t[1]}
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}

export interface AlertWidgetDisplayProps {
  widget: AlertWidget;
}
export const AlertWidgetDisplay = ({ widget }: AlertWidgetDisplayProps) => {
  return (
    <Alert key="danger" variant="danger" className="mb-0">
      <Alert.Heading>
        {widget.data.text}
      </Alert.Heading>
    </Alert>
  )
}

const PdfWidgetDisplay = ({ widget }: MediaWidgetDisplayProps) => {
  const {
    client,
    filename,
  } = widget.data;
  const { t } = useTranslation();

  const openFile = () => {
    const url = `${API_BASE_URL}/api/ui/v1/media/${client}/${filename}`
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Row>
      <Col>
        {widget.title && <h1 className="mt-1 float-left">{widget.title}</h1>}
      </Col>
      <Col>
        <Button className="float-right" onClick={() => openFile()}>
          {t('Open')}
        </Button>
      </Col>
    </Row>
  )
}

export interface MediaWidgetDisplayProps {
  widget: MediaWidget;
}
export const MediaWidgetDisplay = ({ widget }: MediaWidgetDisplayProps) => {
  const {
    media_type,
    client,
    filename,
    width,
    height,
  } = widget.data;
  if (media_type === 'image') {
    return (
      <Image
        fluid
        width={width}
        height={height}
        src={`${API_BASE_URL}/api/ui/v1/media/${client}/${filename}`}
      />
    )
  } else if (media_type === 'pdf') {
    return <PdfWidgetDisplay widget={widget} />
  } else {
    return (
      <pre>{JSON.stringify(widget, null, 2)}</pre>
    )
  }
}

export interface GaugeLineWidgetDisplayProps {
  widget: GaugeLineWidget;
  data: TerseExportResponse;
  number_of_decimals?: number;
  timeZone?: string;
  selectedDepth: IDepth;
  setSelectedDepth: React.Dispatch<number>;
  depths: IDepth[];
  dashboardId?: string;
  startDate?: any;
  endDate?: any;
  dashboardTitle?: string;
  firstValuesByUnitId?: { [key: number]: number };
}
export const GaugeLineWidgetDisplay = (props: GaugeLineWidgetDisplayProps) => {
  const {
    widget,
    data,
    number_of_decimals,
    timeZone,
    selectedDepth,
    setSelectedDepth,
    depths,
    firstValuesByUnitId,
  } = props;
  const { t } = useTranslation();
  const [redrawChart, setRedrawChart] = useState(true);
  const [visibleGaugeSourceId, setVisibleGaugeSourceId] = useState(
    widget?.data?.extraProps?.gauge.one_gauge_for_multiple_values
      ?
      widget.data.sources[0].id : 0
  )
  const dynamicAlarmLimit = widget?.data?.extraProps?.gauge?.dynamic_alarm_limit || false;

  const {
    sources,
    extraProps = {},
  } = widget.data;
  const {
    min,
    max,
    gauge = {},
  } = extraProps;

  const {
    alarm_limit_visible = true,
  } = gauge;
  const green_start_value = gauge.green_start_value ? gauge.green_start_value : 0;

  const [showAlarmLimits, setShowAlarmLimits] = useState(alarm_limit_visible);
  const [showAirTemperature, setShowAirTemperature] = useState(false);


  const meter =
    sources.map(source => {
      if (!source.extra_data?.hide_gauge || source.id === visibleGaugeSourceId) {
        const d = source.type === 'unit' ? data.units : data.formulas;
        // @ts-ignore
        const sourceData = source.extra_data.prism_data ? getPrismData(d[source.id], source.id, firstValuesByUnitId, source.symbol) : d[source.id];
        const sourceGreen = source.extra_data.green
        const sourceYellow = source.extra_data.yellow
        const sourceRed = source.extra_data.red
        const reverseColors = source.extra_data.reverse_colors ? source.extra_data.reverse_colors : false;
        const sourceCustomColors = source.extra_data.custom_color_limits
        const needleInMiddle = source.extra_data.needle_in_middle
        const sourceMiddle = source.extra_data.middle
        const gaugeTitle = source.extra_data.gauge_title

        if (!visibleGaugeSourceId) {
          return (
            <Col key={`gauge-${source.id}`}>
              <Gauge
                title={gaugeTitle ? gaugeTitle : source.title}
                symbol={source.symbol}
                data={sourceData || []}
                loading={!sourceData}
                number_of_decimals={number_of_decimals}
                green={sourceGreen ? sourceGreen : gauge.green}
                yellow={sourceYellow ? sourceYellow : gauge.yellow}
                red={sourceRed ? sourceRed : gauge.red}
                reverseColors={reverseColors}
                needle_in_middle={needleInMiddle}
                middle={sourceMiddle}
                customColors={sourceCustomColors}
                {...gauge}
                green_start_value={green_start_value}
              />
            </Col>
          );
        } else {
          return (
            <Col key={`gauge-${source.id}`}>
              <Gauge
                title={source.title}
                symbol={source.symbol}
                data={sourceData || []}
                loading={!sourceData}
                number_of_decimals={number_of_decimals}
                {...gauge}
              />
              {widget.data.sources.map(source =>
                source.extra_data.hide_gauge_button ?
                null
                :
                <Button
                  key={`selectGaugeSourceIdButton-${source.id}`}
                  className={`btn-xs mr-2 mt-5 ${visibleGaugeSourceId === source.id ? "active" : ""}`}
                  onClick={() => setVisibleGaugeSourceId(source.id)}
                >
                  {source.title}
                </Button>
              )}
            </Col>
          );
        }
      } else {
        return null;
      }
    })
  const lineChartDatasets = sources
    .filter(source => source.extra_data.show_in_chart !== false)
    .map(source => {
      const d = source.type === 'unit' ? data.units : data.formulas;
      // @ts-ignore
      const sourceData = source.extra_data.prism_data ? getPrismData(d[source.id], source.id, firstValuesByUnitId, source.symbol) : d[source.id] || [];

      return {
        label: source.title || source.id.toString(),
        data: sourceData,
        symbol: source.symbol,
        type: String(source.type),
        hide_axis_label: source.extra_data.hide_axis_label ? source.extra_data.hide_axis_label : false,
        hidden_by_default: source.extra_data.hidden_by_default ? source.extra_data.hidden_by_default : false,
        axis_id: source.extra_data.axis_id ? source.extra_data.axis_id : null,
        extra_data: source.extra_data || {},
      }
    });

  // Add alarm limit values to dataset
  let alarm_limit_increase = 0;
  if (gauge.alarm_limit && showAlarmLimits) {
    if (lineChartDatasets[0].data.length > 0) {
      let alarmLimitStartTime = "", alarmLimitEndTime = "";
      lineChartDatasets.forEach((dataset) => {
        if (!alarmLimitStartTime || dataset.data[0][0] < alarmLimitStartTime) {
          alarmLimitStartTime = dataset.data[0][0];
        }
        if (!alarmLimitEndTime || dataset.data[dataset.data.length - 1][0] > alarmLimitEndTime) {
          alarmLimitEndTime = dataset.data[dataset.data.length - 1][0];
          alarm_limit_increase = dynamicAlarmLimit ? dataset.data[dataset.data.length - 1][1] : 0;
        } else {
          alarm_limit_increase -= dynamicAlarmLimit ? dataset.data[dataset.data.length - 1][1] : 0;
        }
      })

      gauge.alarm_limit.forEach((limit: any) => {
        lineChartDatasets.push({
          label: t('Alarm limit') + " " + limit[0],
          data: [
            [alarmLimitStartTime, limit[1] + alarm_limit_increase],
            [alarmLimitEndTime, limit[1] + alarm_limit_increase]
          ],
          symbol: lineChartDatasets[0].symbol,
          type: "Alarm limit",
          hide_axis_label: true,
          hidden_by_default: false,
          axis_id: 1,
          extra_data: {},
        });
      })
    }
  }

  // Dataset filtering for the inclinometer and temperature dashboard line chart
  let filteredLineChartDatasets = lineChartDatasets;
  if (gauge.widget_type === 'inclinometer-line' && selectedDepth) {
    filteredLineChartDatasets = getInclinometerChartData(
      data.widgets[widget.id]?.datasets, selectedDepth,
      null, false, gauge.convergence, t
    );
  } else if (gauge.widget_type === 'temperature-line' && selectedDepth) {
    filteredLineChartDatasets = getInclinometerChartData(lineChartDatasets, selectedDepth, true, showAirTemperature);
  }

  let tempLine = false;
  if (['temperature-line'].includes(gauge.widget_type)) {
    tempLine = true;
  }

  const lineChart =
    <LineChart
      min={min}
      max={max}
      datasets={filteredLineChartDatasets}
      number_of_decimals={number_of_decimals}
      individual_axes={gauge.individual_axes}
      redrawChart={redrawChart}
      setRedrawChart={setRedrawChart}
      timeZone={timeZone}
      axisTitle={gauge.axis_title}
      stepSize={gauge.step_size}
      chartHeightVh={gauge.chart_height_vh}
    />
    ;

  const toggleAlarmLimitsButton =
    <Form.Check
      className="float-right"
      id={`switch-${widget.id}`}
      type="switch"
      label={t('Alarm limits')}
      onChange={() => setShowAlarmLimits(!showAlarmLimits)}
      checked={showAlarmLimits}
    />

  const resetZoomButton =
    <Button
      className={`${redrawChart ? "invisible" : "visible"} btn-sm float-left`}
      onClick={() => setRedrawChart(true)}
    >
      {t('Reset')}
    </Button>

  let selectDepthButton = null;
  let toggleTempValuesButton = null;
  if (selectedDepth) {
    selectDepthButton =
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedDepth.title}
          className="float-right mr-2"
          size="sm"
        >
          {depths.map((depth) =>
            <Dropdown.Item
              onClick={() => {
                setShowAirTemperature(false);
                setSelectedDepth(depth.key)
              }}
              key={depth.title}
            >
              {depth.title}
            </Dropdown.Item>
          )}
        </DropdownButton>
        <Form.Label column sm="1" className="float-right mr-2">
          {gauge.convergence ? "Segmentti:" : `${t('Depth')}:`}
        </Form.Label>
      </>
  }
  if (tempLine) {
    toggleTempValuesButton =
      <>
        <Form.Label column sm="2">
          Ilma:
        </Form.Label>
        <Form.Check
          className="float-right mt-2"
          id={`average-switch-${widget.id}`}
          type="switch"
          inline
          label=""
          onChange={() => setShowAirTemperature(!showAirTemperature)}
          checked={showAirTemperature}
        />
      </>
  }

  let emptyChart = true;
  for (const key in filteredLineChartDatasets) {
    emptyChart = filteredLineChartDatasets[key].data.length === 0;
  }

  return (
    <>
      <div className={selectedDepth || tempLine ? "container" : "container-fluid"}>
        {widget.title && (
          <h2 className="text-center pb-4 pt-2">
            {t(widget.title)}
            {selectedDepth ? ` (${selectedDepth.title})` : ''}
          </h2>
        )}
        {gauge.type_side_by_side ?
          <Row>
            <>
              {meter}
            </>
            <Col md={8} key={`line-${widget.id}`}>
              {filteredLineChartDatasets ? lineChart : null}
              {gauge.alarm_limit ? toggleAlarmLimitsButton : null}
              {resetZoomButton}
              {!emptyChart && (
                <ExcelExport
                  widget={props.widget}
                  dashboardTitle={props.dashboardTitle}
                  dashboardId={props.dashboardId}
                  startDate={props.startDate}
                  endDate={props.endDate}
                />
              )}
            </Col>
          </Row>
          :
          <>
            {!gauge.gauge_after_chart &&
              <Row>
                {meter}
              </Row>
            }
            <Row className="my-4">
              <Col>
                {lineChart}
                {gauge.alarm_limit ? toggleAlarmLimitsButton : null}
                {resetZoomButton}
                <div className="text-right">
                  {selectedDepth ? selectDepthButton : null}
                  {tempLine ? toggleTempValuesButton : null}
                </div>
              </Col>
            </Row>
            {gauge.gauge_after_chart &&
              <Row>
                {meter}
              </Row>
            }
          </>
        }
      </div>
    </>
  )
}

const getInclinometerChartData = (
  data: any,
  selectedDepth: {axisTitleKey: number, key: number, title: string},
  temperatureValues?: any,
  showAirTemperature?: boolean,
  convergence?: boolean,
  t?: any
) => {

  let labels: string[] = [];
  if (temperatureValues && data) {
    labels = [
      data[0].label.split('-')[0],
      data[Math.floor(data.length / 4)].label.split('-')[0],
      data[Math.floor(data.length / 4 * 2)].label.split('-')[0],
      data[Math.floor(data.length / 4 * 3)].label.split('-')[0],
    ]
  }

  let dataToChart = null;
  // Dataset filtering for the inclinometer dashboard line chart
  if (selectedDepth && data) {
    if (temperatureValues) {
        dataToChart = data.filter(
          (ds: any) => [
            `${labels[0]}-${selectedDepth.axisTitleKey}`,
            `${labels[1]}-${selectedDepth.axisTitleKey}`,
            `${labels[2]}-${selectedDepth.axisTitleKey}`,
            `${labels[3]}-${selectedDepth.axisTitleKey}`,
            showAirTemperature && 'Air temperature'
          ].includes(ds.label)
          || (showAirTemperature && ds.extra_data.air_temp)
        );
    } else {
      dataToChart = data.filter(
        (ds: any) => [`X-${selectedDepth.axisTitleKey + 1}`, `Y-${selectedDepth.axisTitleKey + 1}`].includes(ds.label)
      );
    }

    dataToChart = dataToChart.map((ds: any) => {
      return {
        label: temperatureValues ?
          (ds.label.includes('Air temperature') ? ds.label.replace('Air temperature', 'Ilma') : ds.label.split("-")[0])
          : ds.label,
        data: ds.data,
        symbol: ds.symbol,
        type: String(ds.type)
      }
    })
    if (dataToChart.length > 0) {
      if (convergence) {
        dataToChart[0].label = `Segmentti ${selectedDepth.title}`;
      } else if (!temperatureValues) {
        dataToChart[0].label = t ? t('X Direction') : "X Suunta";
        if (dataToChart.length > 1) dataToChart[1].label = t ? t('Y Direction') : "Y Suunta";
      }
    }
  }

  return dataToChart;
}
