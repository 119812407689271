let isIE = false;
try {
  if((window as any).isOldIE) {
    // this is defined in html conditional comments
    isIE = true;
  } else if (!!window.MSInputMethodContext && !!(document as any).documentMode) {
    // ie 11
    isIE = true;
  } else if ((navigator.userAgent || '').indexOf('MSIE 10') >= 0) {
    // ie 10
    isIE = true;
  }
} catch (e) {
  console.warn('Error detecting IE:', e);
}

export {
  isIE
}
