import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initDashboard, selectDashboardState } from './dashboardsSlice';
import Spinner from 'react-bootstrap/Spinner';
import { Dashboard, DashboardDataResponse } from '../../api';


export interface LoadedComponentProps {
  dashboard: Dashboard;
  data: DashboardDataResponse;
  isLoading: boolean;
  dataPeriodMinutes: number;
}
export interface DashboardLoaderProps {
  id: string;
  children: (props: LoadedComponentProps) => React.ReactNode;
}
const DashboardLoader = ({ id, children }: DashboardLoaderProps) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    currentDashboard,
    currentDashboardData,
    dataPeriodMinutes
  } = useSelector(selectDashboardState(id));

  useEffect(() => {
    dispatch(initDashboard(id));
  }, [dispatch, id])

  if(!currentDashboard) {
    return (
      <Spinner animation="border" />
    )
  }

  return (
    <div>
      {children({
        dashboard: currentDashboard,
        data: currentDashboardData,
        isLoading: isLoading,
        dataPeriodMinutes: dataPeriodMinutes,
      })}
    </div>
  )
}
export default DashboardLoader;
