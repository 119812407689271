import LayerGroup from "ol/layer/Group";
import LayerTile from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ';

let MAP_URL;
let urlProjection = 'webmercator/';
MAP_URL = process.env.REACT_APP_MAP_URL; // ie. http://localhost:8080/tiles

let FULL_OSM_MAP_URL = 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png';
if (MAP_URL === undefined) {
  MAP_URL = 'https://tiles.kartat.kapsi.fi';
  urlProjection = '';
} else {
  // Blah blah, just use openstreetmap for now
  if (process.env.REACT_APP_USE_MAP_URL_FOR_OSM === 'true') {
    FULL_OSM_MAP_URL = `${MAP_URL}/osm/${urlProjection}{z}/{x}/{y}.png`;
  }
}

const mapLayer = [
  new LayerGroup({
    'title': 'Layers',
    layers: [
      new LayerTile({
        title: 'Ortoilmakuva',
        visible: false,
        type: 'base',
        source: new XYZ({
          url: `${MAP_URL}/ortokuva/${urlProjection}{z}/{x}/{y}.png`
        })
      }),
      new LayerTile({
        title: ' Peruskartta',
        visible: false,
        type: 'base',
        source: new XYZ({
          url: `${MAP_URL}/peruskartta/${urlProjection}{z}/{x}/{y}.png`
        })
      }),
      new LayerTile({
        title: 'Taustakartta',
        visible: false,
        type: 'base',
        source: new XYZ({
          url: `${MAP_URL}/taustakartta/${urlProjection}{z}/{x}/{y}.png`
        })
      }),
      new LayerTile({
        title: 'Oletus',
        visible: true,
        type: 'base',
        source: new XYZ({
          url: FULL_OSM_MAP_URL,
        })
      }),
    ]
  })
]

export default mapLayer;
