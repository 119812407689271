import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { color } from 'features/dataloggerCharts/legacyConfig';

export interface ConvergenceChartProps {
  chartData: any;
  chartId: string;
  inverseXAxis: boolean | false;
}

const ConvergenceChart = (props: ConvergenceChartProps) => {
  let { chartData, chartId } = props;
  let xValues = [];
  let yValues = [];
  let xMax = 0;
  let xMin = 0;
  let yMax = 0;
  let yMin = 0;
  let latestMeasurementDate = null;

  if (chartData) {
    xValues = chartData.flatMap(
      (data: any) => (!isNaN(data.x_value_1) && !isNaN(data.x_value_2)) && [data.x_value_1, data.x_value_2]
    )
    yValues = chartData.flatMap(
      (data: any) => (!isNaN(data.y_value_1) && !isNaN(data.y_value_2)) && [data.y_value_1, data.y_value_2]
    )
    xMax = Math.max(...xValues);
    xMin = Math.min(...xValues);
    yMax = Math.max(...yValues);
    yMin = Math.min(...yValues);
  }

  am4core.useTheme(am4themes_animated);
  let chart = am4core.create(chartId, am4charts.XYChart);
  chart.legend = new am4charts.Legend();
  chart.padding(10, 10, 10, 10);
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = "zoomXY";
  chart.numberFormatter.numberFormat = "####";

  function createAxis(list: any, titleText: string, min: number, max: number, inverseAxis: boolean) {
    let axis = list.push(new am4charts.ValueAxis());
    axis.title.text = titleText;
    axis.renderer.opposite = true;
    axis.renderer.inversed = inverseAxis;
    axis.title.rotation = 0;
    axis.title.fontWeight = "bold";
    axis.title.marginLeft = 10;
    axis.title.marginBottom = 10;
    axis.min = min;
    axis.max = max;
    axis.renderer.minGridDistance = 50;
  }

  // Create axes
  createAxis(chart.xAxes, "cm", xMin - 50, xMax + 50, props.inverseXAxis);
  createAxis(chart.yAxes, "cm", yMin - 50, yMax + 50, false);

  // Create series
  // For limit line
  const limitSeries = chart.series.push(new am4charts.LineSeries());
  limitSeries.name = "Hälytysrajat";
  limitSeries.data = chartData;
  limitSeries.dataFields.valueX = "x_value_1";
  limitSeries.dataFields.valueY = "y_value_1";
  limitSeries.stroke = am4core.color(color.success._100);
  limitSeries.strokeWidth = 22;
  limitSeries.connect = false;

  // For first measurements
  const series = chart.series.push(new am4charts.LineSeries());
  series.name = "Asennustilanne";
  series.data = chartData;
  series.dataFields.valueX = "x_value_1";
  series.dataFields.valueY = "y_value_1";
  series.stroke = am4core.color(color.primary._300);
  series.strokeWidth = 2;
  series.connect = false;

  if (series.data.length > 0) {
    const latestDate = new Date(series.data[0].timestamp2)
    latestMeasurementDate = `${latestDate.getDate()}.${latestDate.getMonth() + 1}.${latestDate.getFullYear()}`
  }

  // For newest measurements
  const series2 = chart.series.push(new am4charts.LineSeries());
  series2.name = latestMeasurementDate ? latestMeasurementDate : "Nykyinen tilanne";
  series2.data = chartData;
  series2.dataFields.valueX = "x_value_2";
  series2.dataFields.valueY = "y_value_2";
  series2.stroke = am4core.color(color.danger._300);
  series2.fill = am4core.color(color.success._300);
  series2.strokeWidth = 2;
  series2.connect = false;
  // @ts-ignore
  series2.tooltip.getFillFromObject = false;
  // @ts-ignore
  series2.tooltip.background.fill = am4core.color(color.danger._300);

  const bullet = series.bullets.push(new am4charts.CircleBullet());
  bullet.properties.scale = 0.5;
  bullet.tooltipText = "Segment: {category}\nX: {x_value_1} cm, Y: {y_value_1} cm";

  const bullethover = bullet.states.create("hover");
  bullethover.properties.scale = 1;

  const bullet2 = series2.bullets.push(new am4charts.CircleBullet());
  bullet2.properties.scale = 0.5;
  bullet2.tooltipText = "Segment: {category}\nX: {x_value_2} cm, Y: {y_value_2} cm";

  const bullethover2 = bullet2.states.create("hover");
  bullethover2.properties.scale = 1;
  bullet2.circle.fill = am4core.color(color.danger._300);


  return (
    <div id={chartId} style={{ height: "50vw", width: "50vw", margin: "auto" }}></div>
  );

}

export default ConvergenceChart;
