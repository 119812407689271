// terrible IE11 hacks & polyfills go here, expect for things that need to be imported in other modules.
// those go to compat.ts
import 'react-app-polyfill/ie11'; // IE11
import 'react-app-polyfill/stable'; // IE11
import 'core-js/features/string/repeat'; // IE11
import 'fast-text-encoding/text'; // IE11
import { enableES5 } from 'immer'; // IE11
enableES5(); // IE11

// terrible IE11 polyfills
export {};
