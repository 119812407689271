import * as React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { Datapoint, TerseDatapoint } from '../../api';
import { toTerseDatapoints } from '../../api/utils';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

interface CustomColors {
  red: number[]
  yellow: number[]
  green: number[]
}
export interface GaugeProps {
  title?: string;
  subtitle?: string;
  symbol?: string;
  data: Datapoint[] | TerseDatapoint[];
  middle: number;
  green: number;
  yellow: number;
  red: number;
  needle_in_middle: boolean;
  loading?: boolean;
  grayscale_gauge?: boolean;
  number_of_decimals?: number;
  green_start_value?: number;
  max_segment_labels?: number;
  reverseColors?: boolean;
  customColors?: CustomColors;
}
const Gauge = (props: GaugeProps) => {
  const {
    middle,
    green,
    yellow,
    red,
    needle_in_middle,
    loading = false,
    symbol = '',
    grayscale_gauge,
    number_of_decimals,
    green_start_value,
    max_segment_labels,
    reverseColors,
    customColors,
  } = props;

  const terseData = toTerseDatapoints(props.data);
  const values = terseData.map(d => d[1]);
  const lastValue = values[values.length - 1];
  const { t } = useTranslation();
  let segmentColors, max, segmentSettings, customSegmentStops;
  let min = green_start_value ? green_start_value : 0;

  if (!needle_in_middle) {
    if (reverseColors) {
      customSegmentStops = [
        min,
        red,
        yellow,
        green,
      ];
      segmentColors = [
        '#ff005c',
        '#fddf08',
        '#00d49c',
      ];
    } else {
      customSegmentStops = [
        min,
        green,
        yellow,
        red,
      ];
      segmentColors = [
        '#00d49c',
        '#fddf08',
        '#ff005c',
      ];
    }
    max = reverseColors ? green : red

    if (grayscale_gauge) {
      segmentSettings = {
        maxSegmentLabels: max_segment_labels ? max_segment_labels : 4,
        segments: 1000,
        startColor: "#E8E8E8",
        endColor: "#C0C0C0",
      }
    } else {
      segmentSettings = {
        segments: segmentColors.length,
        segmentColors: segmentColors,
        customSegmentStops: customSegmentStops,
      }
    }
  } else {
    if (grayscale_gauge) {
      segmentColors = [
        "#E8E8E8",
      ];
    } else {
      segmentColors = [
        '#ff005c',
        '#fddf08',
        '#00d49c',
        '#fddf08',
        '#ff005c',
      ];
    }
    min = middle - red;
    max = middle + red;

    let segmentStops = [
      min,
      middle - yellow,
      middle - green,
      middle + green,
      middle + yellow,
      max,
    ]

    if (customColors) {
      min = customColors.red[0];
      max = customColors.red[1];
      segmentStops = [
        min,
        customColors.yellow[0],
        customColors.green[0],
        customColors.green[1],
        customColors.yellow[1],
        max,
      ]
    }

    segmentSettings = {
      segments: segmentColors.length,
      segmentColors: segmentColors,
      customSegmentStops: segmentStops
    }
  }

  return (
    <div style={{
      width: '100%',
    }}>
      {props.title && (
        <h4 className="text-center">{t(props.title)}</h4>
      )}
      {props.subtitle && (
        <h5 className="text-center">{t(props.subtitle)}</h5>
      )}
      <div style={{
        width: '100%',
        position: 'relative',
        paddingBottom: 'calc(50% + 30px)',
      }}>
        <div style={{
          position: 'absolute',
          width: '100%',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        }}>
          {(lastValue !== undefined) ? (
            <ReactSpeedometer
              key={`has-data`}
              fluidWidth
              minValue={min}
              maxValue={max}
              value={Math.min(Math.max(lastValue, min), max) /* We limit this to the visible range of the gauge */}
              ringWidth={30}
              {...segmentSettings}
              currentValueText={`${lastValue.toFixed(number_of_decimals)} ${symbol}`}
              labelFontSize="10px"
              //valueFormat=".2f"
            />
          ) : (
              <>
                <ReactSpeedometer
                  key={`no-data`}
                  fluidWidth
                  minValue={min}
                  maxValue={max}
                  value={undefined}
                  ringWidth={30}
                  {...segmentSettings}
                  needleHeightRatio={0}
                  needleColor="transparent"
                  currentValueText={
                    loading ? (
                      ''
                    ) : (
                        t('No data')
                      )
                  }
                  labelFontSize="10px"
                  //valueFormat=".2f"
                />
                {loading && (
                  <div style={{
                    position: 'absolute',
                    textAlign: 'center',
                    width: '100%',
                    left: '0',
                    right: '0',
                    bottom: '0',
                  }}>
                    <Spinner animation="border" />
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  )
}
export default Gauge;
