import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import { Col, Container, Row } from 'react-bootstrap';
import { DashboardDataResponse, Widget } from '../../api';

interface ColumnChartProps {
  widget: Widget;
  data: DashboardDataResponse;
  chartId: string;
}


const getMonthName = (monthNumber: number) => {
  const months = [
    "Tammikuu",
    "Helmikuu",
    "Maaliskuu",
    "Huhtikuu",
    "Toukokuu",
    "Kesäkuu",
    "Heinäkuu",
    "Elokuu",
    "Syyskuu",
    "Lokakuu",
    "Marraskuu",
    "Joulukuu"
  ]

  return months[monthNumber-1];
}

const ColumnChart = (props: ColumnChartProps) => {
  const { widget, data, chartId } = props;
  const sources = widget.data.sources
  const rainDataSource = sources.filter((source: any) => source.title === "Sadanta")[0]
  let filteredData: any = {};
  const [rainAccumulation, setRainAccumulation] = useState("")
  const [chartData, setChartData] = useState<any[]>([])
  const viewDataByMonths = widget.data.extraProps.view_data_by_months;

  const calculateRainAccumulation = (startDate?: Date, endDate?: Date) => {
    let newAccumulation = 0.0
    if (rainDataSource && data.units[rainDataSource.id]) {
      if (startDate && endDate) {
        data.units[rainDataSource.id].forEach(d => {
          const dataDate = new Date(d[0])
          if (d[1] !== 0 && dataDate >= startDate && dataDate <= endDate) {
            newAccumulation += d[1]
          }
        })
      } else {
        const rainAccumulationArray = data.units[rainDataSource.id].map(data => data[1])
        newAccumulation = rainAccumulationArray.reduce((a, b) => a + b, 0)
      }

      setRainAccumulation(newAccumulation.toFixed(1).toString())
    }
  }

  const dateAxisChanged = (ev: any) => {
    let startDate: Date = new Date(ev.target.minZoomed);
    let endDate: Date = new Date(ev.target.maxZoomed);
    calculateRainAccumulation(startDate, endDate)
  }

  useEffect(() => {
    // This works only for one data source for now
    if (viewDataByMonths) {
      if (data.units[sources[0].id]) {
        data.units[sources[0].id].forEach((d: any[]) => {
          const date = d[0].split("T")[0].split("-");
          const title = `${getMonthName(parseInt(date[1]))} ${date[0]}`;
          const measurement = d[1];
          if (!filteredData[title]) {
            filteredData[title] = measurement;
          } else if (measurement > 0) {
            filteredData[title] += measurement;
          }
        })
      }
      const newChartData: any[] = []
      for (let d in filteredData) {
        newChartData.push({
          category: d,
          [sources[0].title]: filteredData[d]
        })
      }
      setChartData(newChartData)
    } else {
      sources.forEach((source: any) => {
        const sourceTitle = source.title
        if (data.units[source.id]) {
          data.units[source.id].forEach(data => {
            const date = data[0]
            const measurement = data[1]
            if (!filteredData[date]) {
              filteredData[date] = {
                [sourceTitle]: measurement
              };
            } else if (measurement) {
              filteredData[date][sourceTitle] = measurement
            }
          })
          const newChartData: any[] = []
          Object.keys(filteredData).sort().forEach(function (key) {
            newChartData.push({
              category: new Date(key),
              ...filteredData[key]
            })
          });
          setChartData(newChartData)
        }
      })
      calculateRainAccumulation()
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_frozen);
    let chart = am4core.create(chartId, am4charts.XYChart);
    chart.colors.step = 2;
    chart.data = chartData;
    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.dateFormatter.dateFormat = "dd.MM.yyyy H:mm";

    let title = chart.titles.create();
    title.text = widget.title;
    title.fontSize = 25;
    title.marginBottom = 30;

    if (viewDataByMonths) {
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
    } else {
      let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
      categoryAxis.dateFormats.setKey("day", "dd.MM");
      categoryAxis.periodChangeDateFormats.setKey("day", "dd.MM");
      categoryAxis.events.on("startendchanged", dateAxisChanged);
    }

    sources.forEach((source: any) => {
      if (source.extra_data.serie_type === 'column') {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = `${source.title} (${source.symbol})`;
        valueAxis.min = 0;
        valueAxis.renderer.opposite = source.extra_data.axis_id === 2;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = source.title;
        if (viewDataByMonths) {
          series.dataFields.categoryX = "category";
        } else {
          series.dataFields.dateX = "category";
        }
        series.tooltipText =  viewDataByMonths ?
          "{categoryX}\n" + source.title + ": [bold]{valueY}[/] " + source.symbol
          :
          "{dateX}\n" + source.title + ": [bold]{valueY}[/] " + source.symbol
        series.columns.template.fillOpacity = .8;
        series.name = source.title

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
      } else if (source.extra_data.serie_type === 'line') {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = `${source.title} (${source.symbol})`;
        valueAxis.renderer.opposite = source.extra_data.axis_id === 2
        valueAxis.renderer.grid.template.disabled = true;

        let series = chart.series.push(new am4charts.LineSeries())
        series.dataFields.valueY = source.title;
        series.dataFields.dateX = "category";
        series.strokeWidth = 3;
        series.yAxis = valueAxis;
        series.name = source.title

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 1.0;
        series.tooltipText = "{dateX}\n" + source.title + ": {valueY} " + source.symbol
        series.showTooltipOn = "hover"
        let hoverState = bullet.states.create("hover");
        hoverState.properties.scale = 4.0;
      }
    })

    return () => {
      chart.dispose();
    };
  // eslint-disable-next-line
  }, [chartId, chartData, sources, viewDataByMonths, widget.title])

  return (
    <Container fluid>
      <Row>
        <Col>
          <div id={chartId} style={{ height: "600px" }} />
        </Col>
      </Row>
      {rainDataSource && rainAccumulation && (
        <Row>
          <Col>
            Sadekertymä: <b>{rainAccumulation}</b> {rainDataSource.symbol}
          </Col>
        </Row>
      )}

    </Container>
  )
}

export default ColumnChart;
