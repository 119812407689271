import * as React from 'react';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Subheader from '../components/layout/Subheader';
import { useParams } from 'react-router-dom';
import DashboardLoader from '../features/dashboards/DashboardLoader';
import DashboardDisplay from '../features/dashboards/DashboardDisplay';

const DashboardDetailsPage: React.FC = () => {
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs />
      <DashboardLoader id={id}>
        {(props) => (
          <div>
            <Subheader>{props.dashboard.title}</Subheader>
            <DashboardDisplay {...props} />
          </div>
        )}
      </DashboardLoader>
    </div>
  );
};
export default DashboardDetailsPage;
