import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { fetchDatalogger, selectDataloggerByIdcode } from './dataloggersSlice';
import { Datalogger } from '../../api';

interface DataloggerLoaderProps {
  idcode: string;
  children: (datalogger: Datalogger) => React.ReactNode;
  breadcrumbs?: boolean;
  className?: string;
}
const DataloggerLoader = (props: DataloggerLoaderProps) => {
  const {
    idcode,
    children,
    className,
    breadcrumbs = true
  } = props;
  const datalogger = useSelector(
    state => selectDataloggerByIdcode(state, idcode)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDatalogger(idcode));
  }, [dispatch, idcode]);

  if(!datalogger) {
    return (
      <div className={className}>
        {breadcrumbs && <Breadcrumbs />}
        <>
          <Spinner animation="border" />
        </>
      </div>
    )
  }

  return (
    <div className={className}>
      {breadcrumbs && <Breadcrumbs />}
      <>
        {children(datalogger)}
      </>
    </div>
  );
};
export default DataloggerLoader;
