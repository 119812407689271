import * as React from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Datalogger } from '../../api';
import { useTranslation } from 'react-i18next';

const formatTimeAgo = (selector: string) => (row: any) => {
  const dateStr = row[selector];
  if(!dateStr) {
    return '(never)';
  }
  return <Moment fromNow>{row[selector]}</Moment>;
}
const formatDataloggerLink = (selector: string) => (row: any) => (
  <Link to={`/dataloggers/${row.idcode}/`}>{row[selector]}</Link>
)

//TODO: Change the correct type
const getColumns = (t: any) => [
  {
    name: t('Name'),
    selector: 'name',
    sortable: true,
    wrap: true,
    grow: 1,
    format: formatDataloggerLink('name'),
  },
  {
    name: t('Code'),
    selector: 'idcode',
    sortable: true,
    grow: 0,
    format: formatDataloggerLink('idcode'),
  },
  {
    name: t('Latest measurement'),
    selector: 'lastmeasuring',
    sortable: true,
    wrap: true,
    grow: 1,
    format: formatTimeAgo('lastmeasuring'),
  },
  {
    name: t('Latest data post'),
    selector: 'lastdatapost',
    sortable: true,
    wrap: true,
    grow: 1,
    format: formatTimeAgo('lastdatapost'),
  },
  {
    name: t('State'),
    selector: () => '',
    sortable: true,
    wrap: true,
    compact: true,
    grow: 0,
  },
];

interface DataloggerListProps {
  dataloggers: Datalogger[];
  showSearch?: boolean;
}
const DataloggerList: React.FC<DataloggerListProps> = (props) => {
  const {
    dataloggers,
    showSearch = true
  } = props;
  const [search, setSearch] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { t } = useTranslation();

  const onSearch = (e: any) => {
    setSearch(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  }

  let filteredDataloggers = dataloggers;
  if (search && showSearch) {
    filteredDataloggers = filteredDataloggers.filter(
      (datalogger: Datalogger) => (
        datalogger.idcode.indexOf(search) >= 0 ||
        datalogger.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
    )
  }

  return (
    <div>
      {showSearch && (
        <Form.Group>
          <Form.Control
            type="text"
            value={search}
            placeholder={t('Search')}
            onChange={onSearch}
          />
        </Form.Group>
      )}
      <DataTable
        noHeader
        striped
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortField="name"
        className="table table-bordered table-hover table-striped w-100"
        columns={getColumns(t)}
        data={filteredDataloggers}
      />
    </div>
  );
};
export default DataloggerList;
