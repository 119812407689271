import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Subheader from '../components/layout/Subheader';

const DashboardListPage: React.FC = () => {
  const isLoading = false;
  return (
    <div>
      <Breadcrumbs />
      <Subheader>Dashboards</Subheader>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default DashboardListPage;
