import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app/store';
import { apiRequest, apiRequestRaw, User, UserStateResponse } from 'api';
import {
  discardStoredCredentials,
  loadStoredCredentials,
  storeCredentials,
  TokenCredentials,
} from 'api/credentials';

interface AuthenticationState {
  user: User | null;
  loginError: string | null;
  loginInProgress: boolean;
  initialLoginInProgress: boolean;
}
const initialState: AuthenticationState = {
  user: null,
  loginError: null,
  loginInProgress: false,
  initialLoginInProgress: false,
};

const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginStarted(state) {
      state.loginInProgress = true;
    },
    initialLoginStarted(state) {
      state.initialLoginInProgress = true;
    },
    initialLoginFailed(state) {
      state.initialLoginInProgress = false;
    },
    loginSuccess(state, action: PayloadAction<UserStateResponse>) {
      state.user = action.payload.user;
      state.loginError = null;
      state.loginInProgress = false;
      state.initialLoginInProgress = false;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.loginError = action.payload;
      state.loginInProgress = false;
      state.initialLoginInProgress = false;
    },
    logoutSuccess(state) {
      state.user = null;
    },
  },
});

export const {
  loginStarted,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  initialLoginStarted,
  initialLoginFailed,
} = authentication.actions;
export default authentication.reducer;

interface LoginOpts {
  username: string;
  password: string;
  remember?: boolean;
}
export const login =
  (opts: LoginOpts): AppThunk =>
  async (dispatch) => {
    const { username, password, remember } = opts;

    dispatch(loginStarted());
    let response;
    try {
      response = await apiRequestRaw('/api/ui/v1/login/', {
        method: 'POST',
        data: {
          username,
          password,
        },
      });
    } catch (err) {
      dispatch(loginFailed(err.toString()));
      return;
    }
    if (response.status === 401) {
      dispatch(loginFailed('Invalid username or password'));
      return;
    }
    if (response.status >= 400) {
      dispatch(loginFailed('Login error'));
      return;
    }

    const data = await response.json();
    // NOTE: it's important that we store the credentials right away after receiving the response,
    // *BEFORE* dispatching redux actions, because the stored credentials are used for all subsequent API calls.
    // If we don't store them right away, the app might start fetching data with wrong credentials immediately
    // after loginSuccess is dispatched..
    const credentials: TokenCredentials = {
      token: data.token,
    };
    const storage = remember ? 'localStorage' : 'sessionStorage';
    storeCredentials(credentials, storage);

    dispatch(loginSuccess(data));

    // stupid hack in case it still hangs
    if (process.env.REACT_APP_RELOAD_AFTER_LOGIN === 'true') {
      setTimeout(() => window.location.reload(), 1000);
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  // Wait for response to invalidate the cookies
  // before clearing local cache and reloading the page
  await apiRequest('/api/ui/v1/logout/', { method: 'POST' });

  discardStoredCredentials();
  dispatch(logoutSuccess());

  if (
    process.env.REACT_APP_RELOAD_AFTER_LOGOUT === undefined ||
    process.env.REACT_APP_RELOAD_AFTER_LOGOUT === 'true'
  ) {
    setTimeout(() => window.location.reload(), 100);
  }
};

export const loadInitialState =
  (checkCookie: boolean = false): AppThunk =>
  async (dispatch) => {
    const credentials = loadStoredCredentials();
    if (credentials) {
      dispatch(initialLoginStarted());
      // TODO: handle errors
      const response = await apiRequest<UserStateResponse>(
        '/api/ui/v1/current-user/',
      );
      if (response.authenticated && response.user) {
        dispatch(loginSuccess(response));
      } else {
        dispatch(initialLoginFailed());
        discardStoredCredentials();
      }
    } else if (checkCookie) {
      const response = await apiRequest<UserStateResponse>(
        '/api/ui/v1/cookie-jwt/',
      );
      if (response.jwt) {
        storeCredentials({ jwt: response.jwt }, 'localStorage');
        dispatch(loadInitialState());
      }
    }
  };
