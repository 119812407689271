import * as React from 'react';
import { Datapoint, TerseDatapoint } from '../../api';
import { Line } from 'react-chartjs-2';
import { color } from 'features/dataloggerCharts/legacyConfig';
import { toTerseDatapoints } from 'api/utils';
import moment from 'moment-timezone';
import browserTimeZone from 'utils/browserTimeZone';
import { useTranslation } from 'react-i18next';
require('chartjs-plugin-zoom');

export interface DataSet {
  label: string;
  data: Datapoint[] | TerseDatapoint[];
  symbol?: string;
  type: string;
  hide_axis_label?: boolean;
  hidden_by_default?: boolean;
  axis_id?: number;
  extra_data?: any,
  show_in_chart?: boolean;
}
export interface DataChartProps {
  datasets: DataSet[];
  min?: number;
  max?: number;
  options?: any;
  number_of_decimals?: number;
  individual_axes?: boolean;
  redrawChart?: boolean,
  setRedrawChart?: any,
  timeZone?: string;
  axisTitle?: string;
  stepSize?: number;
  chartHeightVh?: number;
}

const LineChart = (props: DataChartProps) => {
  const {
    datasets,
    min,
    max,
    number_of_decimals,
    individual_axes = false,
    redrawChart,
    setRedrawChart,
    timeZone = browserTimeZone,
    axisTitle,
    stepSize,
    chartHeightVh,
  } = props;
  const { t } = useTranslation();

  const datasetProps = [
    {
      borderColor: color.primary._300,
      pointBackgroundColor: color.primary._500,
      pointBorderColor: color.primary._500,
    },
    {
      borderColor: color.success._300,
      pointBackgroundColor: color.success._500,
      pointBorderColor: color.success._500,
    },
    {
      borderColor: 'rgba(0,255,71,0.76)',
      pointBackgroundColor: 'rgba(0,255,71,0.76)',
      pointBorderColor: 'rgba(0,255,71,0.76)',
    },
    {
      borderColor: color.danger._300,
      pointBackgroundColor: color.danger._500,
      pointBorderColor: color.danger._500,
    },
    {
      borderColor: color.warning._300,
      pointBackgroundColor: color.warning._500,
      pointBorderColor: color.warning._500,
    },
    {
      borderColor: 'rgba(255,0,0,0.76)',
      pointBackgroundColor: 'rgba(255,0,0,0.76)',
      pointBorderColor: 'rgba(255,0,0,0.76)',
    },
    {
      borderColor: 'rgba(0,55,255,0.60)',
      pointBackgroundColor: 'rgba(0,55,255,0.60)',
      pointBorderColor: 'rgba(0,55,255,0.60)',
    },
    {
      borderColor: 'rgba(0,183,0,0.6)',
      pointBackgroundColor: 'rgba(0,183,0,0.6)',
      pointBorderColor: 'rgba(0,183,0,0.6)',
    },
    {
      borderColor: 'rgb(255,234,0)',
      pointBackgroundColor: 'rgba(255,234,0)',
      pointBorderColor: 'rgba(255,234,0)',
    },
  ]
  const chartData = {
    datasets: datasets.map((item, i) => (
      {
        label: t(item.label),
        hidden: item.hidden_by_default,
        extra_data: item.extra_data,
        fill: false,
        pointBorderWidth: 1,
        borderWidth: 3,
        hitRadius: 4,
        pointHoverRadius: 4,
        lineTension: 0,
        data: toTerseDatapoints(item.data).map((datapoint) => ({
          y: datapoint[1].toFixed(number_of_decimals),
          t: datapoint[0],
        })),

        yAxisID: item.axis_id ? item.axis_id : (individual_axes ? `y-${i}` : ""),
        ...item.type === "Alarm limit" || item.label === 'Hälytysraja' ?
          {
            borderColor: color.danger._300,
            pointBackgroundColor: color.danger._500,
            pointBorderColor: color.danger._500,
            borderWidth: 2,
            pointRadius: 0,
          }
          :
          item.label === 'Ilmoitusraja' ?
            {
              borderColor: color.warning._300,
              pointBackgroundColor: color.warning._500,
              pointBorderColor: color.warning._500,
              borderWidth: 2,
              pointRadius: 0,
            }
            :
            datasetProps[!isNaN(item.extra_data?.line_color_index) ? item.extra_data.line_color_index : i] || {},
        ...item.data.length > 50 ?
          { pointRadius: 0, }
          :
          { pointRadius: 2, }
      }
    )),
  };

  let startDate, endDate;
  let timeUnit = 'minute';
  if (chartData.datasets[0]?.data[0]) {
    startDate = moment(chartData.datasets[0].data[0].t);
    endDate = moment(chartData.datasets[0].data.slice(-1)[0].t);
    if ((endDate && startDate) && endDate.diff(startDate, 'hours') >= 24) {
      timeUnit = 'day';
    }
  }
  const yAxisItems = individual_axes ? datasets : (datasets.length ? [datasets[0]] : []);
  const dateDisplayFormats = {
    minute: 'HH:mm',
    day: 'D.M.YYYY'
  };
  let axis_label_count = 0;
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: chartHeightVh ? false : true,
    legend: {
      position: 'bottom',
      labels: {
        filter: function (item: any, chart: any) {
          return props.datasets[item.datasetIndex].type !== "Alarm limit";
        }
      },
    },
    title: {
      display: false,
    },
    // TODO: disable animations or not?
    animation: {
      duration: 0,
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem: any) => {
          return moment.tz(tooltipItem[0].xLabel, timeZone).format('D.M.YYYY HH:mm:ss');
        },
        label: (tooltipItem: any) => {
          const dataset = datasets[tooltipItem.datasetIndex];
          const symbol = dataset.symbol;
          let ret = `${t(dataset.label)}: ${tooltipItem.yLabel}`
          if (symbol) {
            ret += ' ' + symbol;
          }
          return ret;
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-1',
          type: 'time',
          ticks: {
            callback: function (value: string, index: any, values: any) {
              let [dateStr, dateType] = value.split(' ', 2);
              const dateFormat = (dateDisplayFormats as any)[dateType] || dateDisplayFormats.day;
              return moment.tz(dateStr, timeZone).format(dateFormat);
            },
          },
          time: {
            unit: timeUnit,
            displayFormats: {
              minute: 'YYYY-MM-DD[T]HH:mm:ssZZ [minute]',
              day: 'YYYY-MM-DD[T]HH:mm:ssZZ [day]'
            },
            stepSize: stepSize ? stepSize : (timeUnit === "minute" ? 60 : 1),
          }
        },
      ],
      yAxes: yAxisItems.map((item, i) => {
        const { scale_min: scaleMin, scale_max: scaleMax } = item.extra_data || {};
        if (!item.hide_axis_label) {
          axis_label_count++;
        }
        // HACK: for alarm limits, display first label instead as the scale label...
        const labelItem = item.type === "Alarm limit" || item.symbol === yAxisItems[0].symbol ? yAxisItems[0] : item;
        const labelString = individual_axes ? (
          yAxisItems.length > 2 && labelItem.symbol ? labelItem.symbol : labelItem.symbol ? `${t(labelItem.label)} ${labelItem.symbol}` : labelItem.label
        ) : '' || axisTitle ? axisTitle : ""
        return (
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString,
            },
            gridLines: {
              display: true,
              color: "#f2f2f2"
            },
            ticks: {
              beginAtZero: false,
              fontSize: 16,
              min: scaleMin || min,
              max: scaleMax || max,
            },
            id: item.axis_id ? item.axis_id : `y-${i}`,
            position: item.axis_id ? (item.axis_id % 2 === 0 ? 'right' : 'left') : (axis_label_count % 2 === 0 ? 'right' : 'left'),
          }
        )
      }),
    },
    pan: {
      enabled: false,
      mode: 'xy'
    },
    zoom: {
      enabled: true,
      drag: true,
      mode: 'x',
      onZoomComplete: function () { setRedrawChart && (setRedrawChart(false)) }
    },
    hover: {
      mode: 'x'
    },
  };

  return (
    <div style={chartHeightVh ? {height: `${chartHeightVh}vh`, cursor: "zoom-in"} : {cursor: "zoom-in"}}>
      <Line data={chartData} options={chartOptions} redraw={redrawChart} />
    </div>
  )
};

export default LineChart;
