import React from 'react';
import { useTranslation } from 'react-i18next';
import './IeWarningBanner.css';

interface IeWarningBannerProps {
  setShowIeWarning: any;
}

const IeWarningBanner = (props: IeWarningBannerProps) => {
  const { t } = useTranslation();
  const { setShowIeWarning } = props;
  return (
    <div className="ie-warning-banner">
      {t('Internet Explorer is not supported. You should upgrade your browser to')} <a href="https://www.microsoft.com/edge" target="blank">Edge</a>, <a href="https://www.mozilla.org/firefox/new/" target="blank">Firefox</a> {t('or')} <a href="https://www.google.com/intl/us_us/chrome/" target="blank">Chrome</a>.
      <button className="close" onClick={() => setShowIeWarning(false)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

export default IeWarningBanner;