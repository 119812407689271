import { AuthCredentials, getAuthHeader, getCredentials } from "./credentials";
import { Moment } from "moment-timezone";
import i18n from "../i18n";
import { WindData } from '../features/dataloggerMap/DataloggerMap';

export type Url = string;
export type Datetime = string;

export interface User {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
}

export interface Datalogger {
  id: number;
  url: Url;
  units: Url;
  formulas: Url;
  uid: string;
  idcode: string;
  customcode: string;
  status: string;
  name: string;
  description: string;
  measuringinterval: number;
  transmissioninterval: number;
  timezone: string;
  in_utc: boolean;
  active: boolean;
  lat: number | null;
  lon: number | null;
  image: Url;
  firstmeasuring: Datetime;
  lastmeasuring: Datetime;
  measuringcount: number;
  datapostcount: number;
  lastdatapost: Datetime;
  service_endtime: Datetime;
  servicetype: string | null; // enum
  dataquality_controller: string | null; // enum
  owned_by: string | null; // enum
  responsible_service_person: string;
  service_responsibility: string | null;
  customer: string | null;
  internal_attributes: any;
  has_triggered_alerts: boolean;
}

export interface Unit {
  id: number;
  url: Url;
  data: Url;
  datalogger: Url;
  uniquename: string;
  name: string;
  comment: string;
  symbol: string;
  min: number | null;
  max: number | null;
  api_read_only: boolean;
  visible_on_ui2_details_page: boolean;
}

export interface Formula {
  id: number;
  name: string;
  symbol: string;
  visible_on_ui2_details_page: boolean;
}

export interface Datapoint {
  //id: number; // maybe we don't care about this
  value: number;
  timestamp: Datetime;
}
export type UnitDatapoint = Datapoint & {
  unit: Url;
};
export type ExportDatapoint = Datapoint & {
  raw: boolean;
  unit: Url | null;
  formula: Url | null;
};
export type TerseDatapoint = [Datetime, number];
export interface TerseExportResponse {
  units: Record<string, TerseDatapoint[]>;
  formulas: Record<string, TerseDatapoint[]>;
  widgets: any;
}
export type DashboardDataResponse = TerseExportResponse & {
  meta: {
    start: Datetime;
    end: Datetime;
    updatedOn: Datetime;
    dataStart: Datetime | null;
    dataEnd: Datetime | null;
    closestToAlarm: {
      title: string | "";
      value: number | null;
    };
    largestDifferenceAfterInstallation?: { x: number; y: number };
    firstValueDate?: { x: number; y: number };
    day_difference?: any;
    wind_data?: WindData;
    firstValuesByUnitId?: { [key: number]: number };
    totalShiftValue?: number;
  };
};

export interface PartialDashboard {
  id: string;
  title: string;
}
export interface ImageWidget {
  type: "image";
  id: string;
  title: string;
  data: {
    src: string;
  };
}
export interface InfoBoxWidgetInterface {
  type: "info-box";
  id: string;
  title: string;
  data: {
    text: any;
    client: string;
    filename: string;
  };
}

export interface AlertWidgetInterface {
  type: "alert";
  id: string;
  title: string;
  data: {
    text: string;
  }
}

export interface InclinometerWidgetInterface {
  type: "inclinometer-line";
  id: string;
  title: string;
  data: any;
}
export interface TemperatureWidgetInterface {
  type: "temperature-line";
  id: string;
  title: string;
  data: any;
}
export interface InclinometerXyWidgetInterface {
  type: "inclinometer-xy-chart";
  id: string;
  title: string;
  data: any;
}
export interface MediaWidget {
  type: "media";
  id: string;
  title: string;
  data: {
    media_type: string;
    client: string;
    filename: string;
    width?: number | string;
    height?: number | string;
  };
}
export interface Datasource {
  type: "unit" | "formula";
  id: number;
  title: string;
  symbol: string;
  extra_data: any;
  hide_gauge_button: boolean;
  hide_axis_label?: boolean;
  hidden_by_default?: boolean;
  axis_id?: number | null;
  show_in_chart?: boolean | true;
  prism_data?: boolean;
  line_color_index?: number | null;
}
export interface DataWidget {
  id: string;
  title: string;
  data: {
    sources: Datasource[];
    min?: number;
    max?: number;
    extraProps?: Record<string, any>;
  };
}
export interface WindRoseWidgetInterface {
  type: "wind-rose";
  id: string;
  title: string;
  data: any;
  background_image?: string;
  direction_lan?: string;
}

export interface ColumnChartWidgetInterface {
  type: "column-chart";
  id: string;
  title: string;
  data: any;
}

export interface MapWidgetInterface {
  type: "map";
  id: string;
  title: string;
  data: any;
  logger_idcode: string;
  layer_sources: LayerSource[];
  center?: number[];
  zoom?: number;
}

export interface LayerSource {
  id: number;
  layer_id: number;
  ids: number[];
  title: string;
  coordinates: number[];
  style: string;
  limits: {'green': number[], 'yellow': number[], 'red': number[], 'grey': number[]};
  symbol: string | '';
}

export type GaugeWidget = DataWidget & { type: "gauge" };
export type GaugeLineWidget = DataWidget & { type: "gauge-line" };
export type InclinometerWidget = InclinometerWidgetInterface & { type: "inclinometer-line" };
export type TemperatureWidget = TemperatureWidgetInterface & { type: "temperature-line" };
export type InclinometerXyWidget = InclinometerXyWidgetInterface & { type: "inclinometer-xy-chart" };
export type WindRoseWidget = WindRoseWidgetInterface & { type: "wind-rose" };
export type ColumnChartWidget = ColumnChartWidgetInterface & { type: "column-chart" };
export type InfoBoxWidget = InfoBoxWidgetInterface & { type: "info-box" };
export type AlertWidget = AlertWidgetInterface & { type: "alert" };
export type MapWidget = MapWidgetInterface & { type: "map" };
export type Widget =
  | ImageWidget
  | MediaWidget
  | GaugeWidget
  | GaugeLineWidget
  | InclinometerWidget
  | InclinometerXyWidget
  | TemperatureWidget
  | WindRoseWidget
  | ColumnChartWidget
  | InfoBoxWidget
  | AlertWidget
  | MapWidget;
export interface DashboardLayout {
  size: "all" | "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
  template: string;
  hidden?: string;
}
export interface Dashboard {
  id: string;
  title: string;
  widgets: Widget[];
  layouts: DashboardLayout[];
  update_interval?: number | null;
  data_period?: Record<string, number>;
  timeframe_periods?: TimeFramePeriod[];
  number_of_decimals?: number;
  time_zone: string | "";
  type?: string | null;
  data_comparison_feature?: boolean | false;
  installation_date?: any;
  soil_ranges?: any[];
  reference_date_feature?: boolean | false;
  hide_dashboard_header_alarm_value?: boolean | false;
  latest_measurement_timeframe?: boolean | false;
}
export interface TimeFramePeriod {
  label: string;
  minutes: number;
}

export interface UserStateResponse {
  authenticated: boolean;
  user: User | null;
  dashboards: PartialDashboard[];
  jwt: string | null;
}
export type LoginSuccess = UserStateResponse & {
  user: User;
  token: string;
};

export interface PaginatedResponse<T> {
  count: number;
  next: Url | null;
  previous: Url | null;
  results: T[];
}

export interface ApiRequestOpts {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  data?: Record<string, any>;
  credentials?: AuthCredentials;
  qs?: Record<string, string | number | boolean>;
}

let API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (API_BASE_URL === undefined) {
  API_BASE_URL = "/2.0";
}
export { API_BASE_URL };

export async function apiRequestRaw(path: string, opts: ApiRequestOpts = {}): Promise<any> {
  const responseOpts = {
    method: opts.method || "GET",
    headers: new Headers([
      ["Content-Type", "application/json"],
      ["Accept", "application/json"],
      ["Accept-Language", i18n.language],
    ]),
    body: opts.data ? JSON.stringify(opts.data) : undefined,
  };
  const credentials = opts.credentials !== undefined ? opts.credentials : getCredentials();
  if (credentials) {
    responseOpts.headers.set("Authorization", getAuthHeader(credentials));
  }
  const qs = opts.qs || null;

  let urlStr;
  if (path.indexOf("://") >= 0 || path.startsWith("//")) {
    urlStr = path;
  } else {
    urlStr = `${API_BASE_URL}${path}`;
  }
  const url = new URL(urlStr, window.location.toString());
  if (qs) {
    Object.keys(qs).forEach((key) => url.searchParams.append(key, qs[key] as any));
  }
  return await fetch(url as any, responseOpts);
}

export async function apiRequest<T>(path: string, opts: ApiRequestOpts = {}): Promise<T> {
  const request = await apiRequestRaw(path, opts);
  return (await request.json()) as T;
}

// TODO: this should maybe be removed
//type ApiMethod<T> = (page?: number) => Promise<T>;
//function createApiFunction<T>(endpoint: string): ApiMethod<T> {
//  return async (page?: number) => {
//
//    return await apiRequest(endpoint);
//  }
//}

export async function fetchNextPage<T>(response: PaginatedResponse<T>): Promise<PaginatedResponse<T> | null> {
  if (!response.next) {
    return null;
  }
  return await apiRequest(response.next);
}
export async function fetchPreviousPage<T>(response: PaginatedResponse<T>): Promise<PaginatedResponse<T> | null> {
  if (!response.previous) {
    return null;
  }
  return await apiRequest(response.previous);
}
export function numPages(response: PaginatedResponse<any>): number {
  if (response.count <= response.results.length) {
    return 1;
  }
  return Math.ceil(response.count / response.results.length);
}
export async function apiRequestAllPages<T>(path: string, opts?: ApiRequestOpts): Promise<T[]> {
  const results = [];
  let page: PaginatedResponse<T> | null = await apiRequest(path, opts);
  while (page) {
    results.push(...page.results);
    page = await fetchNextPage(page);
  }
  return results;
}

function getIdcode(dataloggerOrIdcode: Datalogger | string): string {
  if (typeof dataloggerOrIdcode === "string") {
    return dataloggerOrIdcode;
  } else {
    return dataloggerOrIdcode.idcode;
  }
}

export async function getDataloggers(): Promise<Datalogger[]> {
  return apiRequestAllPages("/api/v2/dataloggers/?page_size=1000&show_triggered_alerts=true");
}

export async function getDatalogger(idcode: string): Promise<Datalogger> {
  return await apiRequest(`/api/v2/dataloggers/${idcode}/`);
}

export async function getDataloggerUnits(datalogger: Datalogger | string): Promise<Unit[]> {
  const idcode = getIdcode(datalogger);
  return await apiRequestAllPages(`/api/v2/dataloggers/${idcode}/units/`);
}

export async function getDataloggerFormulas(datalogger: Datalogger | string): Promise<Unit[]> {
  const idcode = getIdcode(datalogger);
  return await apiRequestAllPages(`/api/v2/dataloggers/${idcode}/formulas/`);
}

export async function getDashboard(dashboardId: string): Promise<Dashboard> {
  return await apiRequest(`/api/ui/v1/dashboards/${dashboardId}/`);
}

export interface DataPeriodQuery {
  dataPeriod: number;
}
export interface StartEndQuery {
  start: string;
  end: string;
}
export interface DataLatestMeasurementQuery {
  latestMeasurement: boolean;
}
export type GetDashboardDataOpts = DataPeriodQuery | StartEndQuery | DataLatestMeasurementQuery;

export async function getDashboardData(
  dashboardId: string,
  opts?: GetDashboardDataOpts
): Promise<DashboardDataResponse> {
  function isDataPeriodQuery(opts: GetDashboardDataOpts): opts is DataPeriodQuery {
    return (opts as any).dataPeriod !== undefined;
  }

  let qs = {};
  if (opts) {
    if (isDataPeriodQuery(opts)) {
      qs = {
        data_period: opts.dataPeriod,
      };
    } else {
      qs = opts;
    }
  }

  return await apiRequest(`/api/ui/v1/dashboards/${dashboardId}/data/`, { qs });
}

interface GetDatapointsOpts {
  units: Unit[];
  start: Moment;
  end: Moment;
}
export async function getDatapoints(opts: GetDatapointsOpts): Promise<ExportDatapoint[]> {
  const { units, start, end } = opts;

  return await apiRequestAllPages(`/api/v2/export/`, {
    qs: {
      unit_ids: units.map((u) => u.id.toString()).join(","),
      start: start.format(),
      end: end.format(),
    },
  });
}
