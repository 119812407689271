import React, { useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { color } from 'features/dataloggerCharts/legacyConfig';

am4core.useTheme(am4themes_animated);

const TemperatureChart = ({ chartId, chartData }: any) => {
  const xAxisMax = 10;
  const maxValue = chartData ? Math.max(...chartData.map((data: any) => data.value ? Math.abs(data.value) : 0)) : xAxisMax;

  useLayoutEffect(() => {
    let chart = am4core.create(chartId, am4charts.XYChart);
    // Add data
    chart.data = chartData;
    chart.numberFormatter.numberFormat = "####.00";

    let depthAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    depthAxis.dataFields.category = "category";
    depthAxis.title.text = 'Syvyys (cm)';
    depthAxis.renderer.minGridDistance = 1;
    //depthAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = maxValue > xAxisMax ? -maxValue : -xAxisMax;
    valueAxis.max = maxValue > xAxisMax ? maxValue : xAxisMax;
    valueAxis.title.text = "Lämpötila (°C)"

    let timestamp2 = "";
    if (chartData?.length > 0) {
      const d2 = new Date(chartData[0].timestamp2);
      timestamp2 = `${d2.getDate()}.${d2.getMonth()+1}.${d2.getFullYear()} ${("0" + d2.getHours()).slice(-2)}:${("0" + d2.getMinutes()).slice(-2)}`;
    }

    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.name = timestamp2;
    series1.dataFields.valueX = "value";
    series1.dataFields.categoryY = "category";
    series1.strokeWidth = 3;
    series1.stroke = am4core.color(color.primary._300);
    series1.tooltipText = "{valueX.value}";
    if (series1.tooltip) {
      series1.tooltip.getFillFromObject = false;
      series1.tooltip.background.fill = am4core.color(color.primary._600);
    }

    chart.legend = new am4charts.Legend();

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    // Bullets
    const bullet = new am4charts.CircleBullet();
    bullet.circle.strokeWidth = 6;
    bullet.circle.fill = am4core.color("#fff");
    bullet.properties.scale = 0.5;

    const bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1;

    series1.bullets.push(bullet);

  }, [chartData, chartId, maxValue]);
  return (
    <div id={chartId} style={{ height: "700px" }}></div>
  );
}

export default TemperatureChart;
