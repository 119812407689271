import * as React from 'react';
import { useState } from 'react';
import { Dashboard, DashboardDataResponse, DashboardLayout, TimeFramePeriod } from 'api';
import Panel from 'components/layout/Panel';
import WidgetDisplay from './WidgetDisplay';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataDisplayType,
  setDataPeriod,
  setDataRangeEnd,
  setDataRangeStart,
  updateCurrentDashboardData,
} from './dashboardsSlice';
import DateTimeRangeForm from '../../components/forms/DateTimeRangeForm';
import browserTimeZone from '../../utils/browserTimeZone';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';

export interface DashboardDisplayProps {
  dashboard: Dashboard;
  data: DashboardDataResponse;
  isLoading: boolean;
  dataComparison?: boolean;
  resetComparisonData?: boolean;
  setResetComparisonData?: any;
  referenceDateFeature?: boolean;
}

export interface DashboardDateTimeSelectorProps {
  dashboard: Dashboard;
  hideTimeFrames: boolean;
  dataComparison?: boolean;
  resetComparisonData?: boolean | false;
  setResetComparisonData?: any;
  referenceDateFeature?: boolean | false;
}

const DashboardDisplay = (props: DashboardDisplayProps) => {
  const { t } = useTranslation();
  const {
    dashboard,
    data,
    isLoading,
  } = props;

  const [isHidden, setIsHidden] = useState(true)
  const [resetComparisonData, setResetComparisonData] = useState(false);

  return (
    <>
      <div id={`dashboard-${dashboard.id}`}>
        <DashboardHeader
          dashboard={dashboard}
          data={data}
          isLoading={isLoading}
          dataComparison={dashboard.data_comparison_feature}
          resetComparisonData={resetComparisonData}
          setResetComparisonData={setResetComparisonData}
          referenceDateFeature={dashboard.reference_date_feature}
        />
        <div className="dashboard-widgets">
          {dashboard.widgets.map(widget => {
            if (widget?.data?.hidable) {
              return (
                <Panel id={`widget-${widget.id}`} key={widget.id}>
                  <div>
                    <h1 className="float-left">{t(widget.data.hidable_title)}</h1>
                    <button type="button"
                      className="btn btn-primary float-right mb-3"
                      data-toggle="button"
                      aria-pressed="false"
                      onClick={() => setIsHidden(!isHidden)}
                    >
                      {isHidden ? t('Show') : t('Hide')}
                    </button>
                  </div>
                  <div style={isHidden ? { display: 'none' } : {}}>
                    <WidgetDisplay
                      widget={widget}
                      data={data}
                      number_of_decimals={dashboard.number_of_decimals}
                      timeZone={dashboard.time_zone || browserTimeZone}
                      dashboardTitle={dashboard.title}
                      dashboardId={dashboard.id}
                    />
                  </div>
                </Panel>
              )
            } else {
              return (
                <Panel id={`widget-${widget.id}`} key={widget.id}>
                  {widget?.title === 'Lämpötila valitulla syvyydellä' ? <DashboardHeader dashboard={dashboard} data={data} isLoading={isLoading} /> : ''}
                  <WidgetDisplay
                    widget={widget}
                    data={data}
                    number_of_decimals={dashboard.number_of_decimals}
                    timeZone={dashboard.time_zone || browserTimeZone}
                    dataComparison={dashboard.data_comparison_feature}
                    dashboardTitle={dashboard.title}
                    dashboardId={dashboard.id}
                    resetComparisonData={resetComparisonData}
                    soilRanges={dashboard.soil_ranges}
                  />
                </Panel>
              )
            }
          })}
        </div>
      </div>
      <DashboardLayoutStyle dashboard={dashboard} />
    </>
  )
}

const DashboardHeader = (props: DashboardDisplayProps) => {
  const { t } = useTranslation();
  const {
    dashboard,
    data,
    isLoading,
    dataComparison,
    resetComparisonData,
    setResetComparisonData,
    referenceDateFeature,
  } = props;

  const timeFormat = "DD.MM.YYYY HH:mm:ss ZZ"
  const updateInterval = 10000;
  return (
    <div className="dashboard-header">
      <Panel>
        {isLoading && (
          <div style={{ float: 'right', marginTop: '10px' }}>
            <Spinner animation="border" />
          </div>
        )}
        <div>
          <strong>{t('Page updated')}:</strong>{' '}
          <Moment format={timeFormat} interval={updateInterval} tz={dashboard.time_zone}>{data.meta.updatedOn}</Moment>{' '}
          (<Moment fromNow interval={updateInterval} tz={dashboard.time_zone}>{data.meta.updatedOn}</Moment>)
        </div>
        {!['inclinometer', 'temperature', 'wind-rose'].includes(String(dashboard.type)) && !dashboard.hide_dashboard_header_alarm_value ? (
          <div>
            <strong>{t('Closest to the alarm limit')}:</strong>{' '}
            {data.meta.closestToAlarm ?
              (`${data.meta.closestToAlarm.title} (${data.meta.closestToAlarm.value})`)
              :
              ""
            }
          </div>
        ) : ""}
        <DashboardDateTimeSelector
          dashboard={dashboard}
          hideTimeFrames={['inclinometer', 'temperature'].includes(String(dashboard.type))}
          dataComparison={dataComparison}
          resetComparisonData={resetComparisonData}
          setResetComparisonData={setResetComparisonData}
          referenceDateFeature={referenceDateFeature}
        />
      </Panel>
    </div>
  );
}

const DashboardDateTimeSelector = (props: DashboardDateTimeSelectorProps) => {
  const { t } = useTranslation();
  const {
    dashboard,
    hideTimeFrames,
    dataComparison,
    resetComparisonData,
    setResetComparisonData,
    referenceDateFeature,
  } = props;

  // Check for dashboard-specific timeframe overrides
  const timeframePeriods: TimeFramePeriod[] = dashboard.timeframe_periods || [
      {label: '45 min', minutes: 45},
      {label: '24 h', minutes: 1440},
  ];
  const [
    dataDisplayType,
    dataPeriodMinutes,
    dataRangeStart,
    dataRangeEnd,
  ] = useSelector(
    (state: RootState) => [
      state.dashboards.dataDisplayType,
      state.dashboards.dataPeriodMinutes,
      state.dashboards.dataRangeStart,
      state.dashboards.dataRangeEnd,
    ]
  );

  const dispatch = useDispatch();
  const [rangeFormVisible, setRangeFormVisible] = useState(dataDisplayType === 'range');
  const [showComparisonDateAddedMessage, setShowComparisonDateAddedMessage] = useState(false);

  const handleDataPeriodChange = (event: React.SyntheticEvent, minutes: number) => {
    event.preventDefault();
    dispatch(setDataPeriod(minutes));
    dispatch(setDataDisplayType('period'));
    setRangeFormVisible(false);
    dispatch(updateCurrentDashboardData());
  }

  const handleDataDisplayTypeChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setDataDisplayType('latest_measurement'));
    setRangeFormVisible(false);
    dispatch(updateCurrentDashboardData());
  }

  const onChangeDataRangeStart = (value: moment.Moment) => {
    dispatch(setDataRangeStart(value.format()));
    if (dataRangeEnd) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }
  const onChangeDataRangeEnd = (value: moment.Moment) => {
    dispatch(setDataRangeEnd(value.format()));
    if (dataRangeStart) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }

  const resetDataRange = () => {
    if (dataComparison) {
      setResetComparisonData(true);
    }
    dispatch(setDataRangeStart(null));
    dispatch(setDataRangeEnd(null));
    dispatch(updateCurrentDashboardData());
  }

  const onClickCustomPeriod = (event: any) => {
    event.preventDefault();
    setRangeFormVisible(true);
    if (dataDisplayType !== 'range' && dataRangeStart && dataRangeEnd) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }

  const handleComparisonDateChange = (date: any) => {
    if (resetComparisonData) {
      setResetComparisonData(false);
    }
    // TODO: setTimeout to prevent lagging the calendar. though this indicates a deeper issue
    setShowComparisonDateAddedMessage(true);
    setTimeout(() => {
      const comparisonEnd = moment(date);
      // TODO: Check if selected date is after installation date and before now()
      if (!dataRangeStart) {
        dispatch(setDataRangeStart(moment(dashboard.installation_date).format()));
      }
      dispatch(setDataRangeEnd(comparisonEnd.format()));
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }, 10);

    setTimeout(() => {
      setShowComparisonDateAddedMessage(false);
    }, 10000);
  }

  const handleReferenceDateChange = (date: any) => {
    // TODO: setTimeout to prevent lagging the calendar. though this indicates a deeper issue
    setShowComparisonDateAddedMessage(true);
    setTimeout(() => {
      const newReferenceDate = moment(date);
      setResetComparisonData(true);
      dispatch(setDataRangeStart(newReferenceDate.format()));
      dispatch(setDataRangeEnd(moment(new Date()).format()));
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }, 10);

    setTimeout(() => {
      setShowComparisonDateAddedMessage(false);
    }, 10000);
  }

  return (
    <>
      {!dashboard.data_comparison_feature && (
        <div>
          <strong>{t('Data period')}:</strong>{' '}
          {dashboard.latest_measurement_timeframe && (
            <Link
              key={`ui_timeframe_period_latest_measurement`}
              to="#"
              onClick={(event) => handleDataDisplayTypeChange(event)}
              className="mr-5 ml-2"
              style={(dataDisplayType === 'latest_measurement') ? { fontWeight: 'bold' } : {}}
            >
              Viimeisin mittaus
            </Link>
          )}
          {!hideTimeFrames && (
            <>
              {timeframePeriods.map(period =>
                <Link
                  key={`ui_timeframe_period_${period.minutes}`}
                  to="#"
                  onClick={(event) => handleDataPeriodChange(event, period.minutes)}
                  className="mr-5 ml-2"
                  style={(dataDisplayType === 'period' && dataPeriodMinutes === period.minutes) ? { fontWeight: 'bold' } : {}}
                >
                  {period.label}
                </Link>
              )}
            </>
          )}
          <Link
            to="#"
            onClick={onClickCustomPeriod}
            style={(dataDisplayType === 'range') ? { fontWeight: 'bold' } : {}}
            className="mr-5 ml-2"
          >
            {t('Select time period')}
          </Link>

        </div>
      )}
      {rangeFormVisible && !dataComparison && (
        <DateTimeRangeForm
          currentStart={dataRangeStart}
          currentEnd={dataRangeEnd}
          onChangeStart={onChangeDataRangeStart}
          onChangeEnd={onChangeDataRangeEnd}
          resetDataRange={resetDataRange}
        />
      )}

      {dataComparison && (
        <div className="my-2">
          <Row className="mb-2">
            <Col md={!showComparisonDateAddedMessage ? 2 : 10} className="my-2">
              {showComparisonDateAddedMessage ? (
                <span>
              {t('New comparison date added. Please wait a couple of seconds, while the dashboard is updated.')}
            </span>
              ) : (
                <strong className="mr-2">{t('Draw profile for the selected date')}:</strong>
              )}
            </Col>
            {!showComparisonDateAddedMessage && (
              <Col md={10}>
                <Form inline>
                  <Form.Control
                    as={DatePicker}
                    onChange={handleComparisonDateChange}
                  />
                  {dataRangeStart && dataRangeEnd && (
                    <Button
                      className="ml-5"
                      onClick={resetDataRange}
                    >
                      {t('Reset profiles')}
                    </Button>
                  )}
                </Form>
              </Col>
            )}
          </Row>

          {!showComparisonDateAddedMessage && referenceDateFeature && (
            <Row>
              <Col md={2} className="my-2">
                <strong className="mr-2">{t('Change the reference date')}:</strong>
              </Col>
              <Col md={10}>
                <Form inline>
                  <Form.Control
                    as={DatePicker}
                    onChange={handleReferenceDateChange}
                  />
                </Form>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  )
}

const DashboardLayoutStyle = (props: { dashboard: Dashboard }) => {
  const { dashboard } = props;

  if (dashboard.layouts.length === 0) {
    return <></>;
  }

  const namespace = `#dashboard-${dashboard.id} .dashboard-widgets`;

  const layoutStyle = (layout: DashboardLayout) => {
    const rows = layout.template.split('\n').map(s => s.trim()).filter(s => s);
    const numColumns = (rows[0] || '').split(/\s/).length;
    const hiddenWidgets = layout.hidden ? layout.hidden.trim().split(/\s/) : [];

    const styleBody = `
    ${namespace} {
      grid-template-columns: repeat(${numColumns}, 1fr);
      grid-template-areas:\n${rows.map(s => `"${s}"`).join('\n')};
    }
    ${
      dashboard.widgets.map(widget => `
        ${namespace} #widget-${widget.id} {
          display: ${hiddenWidgets.includes(widget.id) ? 'none' : 'block'};
        }
      `).join('\n')
      }`;
    if (layout.size === 'all') {
      return styleBody;
    }
    const minWidths = {
      'all': 0,
      'xxl': 1400,
      'xl': 1200,
      'lg': 992,
      'md': 768,
      'sm': 576,
      'xs': 0,
    }
    const minWidth = minWidths[layout.size];
    return `
    @media (min-width: ${minWidth}px) {
      ${styleBody}
    }
    `;
  }

  return (
    <style>{`
    ${namespace} {
      display: grid;
      grid-gap: 10px;
    }
    ${dashboard.widgets.map(widget => (
      `${namespace} #widget-${widget.id} {
          grid-area: ${widget.id};
        }`
    )).join('\n')}
    ${dashboard.layouts.map(layoutStyle).join('\n')}
    `}</style>
  )
}
export default DashboardDisplay;
