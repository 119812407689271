import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Datalogger, getDatalogger, getDataloggers } from 'api';

const dataloggersAdapter = createEntityAdapter<Datalogger>({
  selectId: datalogger => datalogger.idcode,
})

const selectors = dataloggersAdapter.getSelectors(
  (state: any) => state.dataloggers
);
export const selectDataloggers = selectors.selectAll;
export const selectDataloggerByIdcode = selectors.selectById;

const initialState = dataloggersAdapter.getInitialState({
  isLoading: false,
  allFetched: false,
});
type DataloggersState = typeof initialState;

interface FetchDataloggersOpts {
  forceUpdate?: boolean;
}
export const fetchDataloggers = createAsyncThunk(
  'dataloggers/fetchDataloggers',
  async (opts?: FetchDataloggersOpts | undefined, thunkApi?) => {
    const {
      forceUpdate = false,
    } = (opts || {});
    if(!forceUpdate) {
      const { getState } = (thunkApi || {});
      const rootState = (getState() as any);
      // return cached dataloggers if we have previously fetched all dataloggers
      // and got at least one datalogger in response
      if(rootState.dataloggers.allFetched && rootState.dataloggers.ids.length > 0) {
        return selectDataloggers(rootState);
      }
    }
    return await getDataloggers();
  }
)
export const fetchDatalogger = createAsyncThunk(
  'dataloggers/fetchDatalogger',
  async (idcode: string, { getState }) => {
    const state = (getState() as any).dataloggers as DataloggersState;
    const found = state.entities[idcode];
    if(found)
      return found;

    return await getDatalogger(idcode);
  }
)

const startLoading = (state: DataloggersState) => { state.isLoading = true; };

const dataloggers = createSlice({
  name: 'dataloggers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataloggers.pending, startLoading);
    builder.addCase(fetchDataloggers.fulfilled, (state, action) => {
      dataloggersAdapter.setAll(state, action.payload);
      state.isLoading = false;
      state.allFetched = true;
    })

    builder.addCase(fetchDatalogger.pending, startLoading);
    builder.addCase(fetchDatalogger.fulfilled, (state, action) => {
      dataloggersAdapter.upsertOne(state, action.payload);
      state.isLoading = false;
    })
  },
})

export default dataloggers.reducer;
