import * as React from 'react';
import { Datapoint, Unit } from '../../api';
import { Line } from 'react-chartjs-2';
import { color } from './legacyConfig';
import { UnitWithData } from './chartsSlice';

interface SingleDataChartProps {
  unit: Unit;
  data: Datapoint[];
}
interface MultipleDataChartProps {
  items: UnitWithData[];
  individualAxes?: boolean;
}
type DataChartProps = SingleDataChartProps | MultipleDataChartProps;

const isMultipleProps = (props: DataChartProps): props is MultipleDataChartProps => {
  return !!(props as MultipleDataChartProps).items;
}

const DataChart = (props: DataChartProps) => {
  let items, individualAxes, showAxisLabel: boolean;
  if (isMultipleProps(props)) {
    items = props.items;
    individualAxes = props.individualAxes === undefined ? true : props.individualAxes;
    showAxisLabel = !individualAxes;
  } else {
    const {
      unit,
      data,
    } = props;
    items = [
      {
        unit,
        data,
        loading: false,
      }
    ];
    individualAxes = true;
    showAxisLabel = true;
  }
  const datasetProps = [
    {
      borderColor: color.primary._300,
      pointBackgroundColor: color.primary._500,
      pointBorderColor: color.primary._500,
    },
    {
      borderColor: color.danger._300,
      pointBackgroundColor: color.danger._500,
      pointBorderColor: color.danger._500,
    },
  ]
  const chartData = {
    datasets: items.map((item, i) => (
      {
        label: item.unit.name,
        fill: false,
        pointBorderWidth: 1,
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 10,
        data: item.data.map((datapoint) => ({
          y: datapoint.value,
          t: datapoint.timestamp,
        })),
        ...(datasetProps[i] || {})
      }
    )),
  };
  const yAxisItems = individualAxes ? items : (items.length ? [items[0]] : []);
  const chartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-1',
          type: 'time',
          time:
            {
              unit: 'day',
            }
        },
      ],
      yAxes: yAxisItems.map((item, i) => (
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: showAxisLabel ? (item.unit.name + ' ' + item.unit.symbol) : '',
          },
          gridLines: {
            display: true,
            color: "#f2f2f2"
          },
          ticks: {
            beginAtZero: true,
            fontSize: 11
          },
          id: `y-${i}`,
        }
      )),
    },
  };

  return (
    <Line data={chartData} options={chartOptions} />
  )
};

export default DataChart;
