export interface TokenCredentials {
  token: string;
}
export interface JWTCredentials {
  jwt: string;
}
export interface UsernamePasswordCredentials {
  username: string;
  password: string;
}
export type AuthCredentials =
  | TokenCredentials
  | JWTCredentials
  | UsernamePasswordCredentials;

function isTokenCredentials(
  credentials: AuthCredentials,
): credentials is TokenCredentials {
  return (credentials as TokenCredentials).token !== undefined;
}

function isJWTCredentials(
  credentials: AuthCredentials,
): credentials is JWTCredentials {
  return (credentials as JWTCredentials).jwt !== undefined;
}

const CREDENTIALS_STORAGE_KEY = 'sensdb-credentials';
let loggedInCredentials: AuthCredentials | null = null;
type Storage = 'localStorage' | 'sessionStorage';
const storageOptsInOrder: Storage[] = ['sessionStorage', 'localStorage'];

export function getAuthHeader(credentials: AuthCredentials): string {
  if (isJWTCredentials(credentials)) {
    return `Bearer ${credentials.jwt}`;
  }
  if (isTokenCredentials(credentials)) {
    return `Token ${credentials.token}`;
  }
  return 'Basic ' + btoa(credentials.username + ':' + credentials.password);
}

export function getCredentials(): AuthCredentials | null {
  return loggedInCredentials;
}

export function storeCredentials(
  credentials: AuthCredentials,
  persistentStorage?: Storage,
) {
  loggedInCredentials = credentials;
  if (persistentStorage) {
    window[persistentStorage].setItem(
      CREDENTIALS_STORAGE_KEY,
      JSON.stringify(credentials),
    );
  }
}

export function discardStoredCredentials() {
  loggedInCredentials = null;
  for (let persistentStorage of storageOptsInOrder) {
    window[persistentStorage].removeItem(CREDENTIALS_STORAGE_KEY);
  }
}

export function loadStoredCredentials() {
  for (let persistentStorage of storageOptsInOrder) {
    const credentialsJson = window[persistentStorage].getItem(
      CREDENTIALS_STORAGE_KEY,
    );
    if (credentialsJson) {
      loggedInCredentials = JSON.parse(credentialsJson);
      return loggedInCredentials;
    }
  }
}

// Hack -- if we are given ?login_token=xxxx as query param, store it in localStorage and replace
// the current history state, so that it's not visible if the user presses back in the browser
// TODO: this is not the most secure thing -- we should rather use a one-off time-limited token
// to login and retrieve the actual token
try {
  const queryParams = new URLSearchParams(window.location.search);
  const initialLoginToken = queryParams.get('login_token');
  if (initialLoginToken) {
    storeCredentials({ token: initialLoginToken }, 'localStorage');
    queryParams.delete('login_token');
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${queryParams.toString()}`,
    );
  }
} catch (e) {
  console.error('error with initial login', e);
}
