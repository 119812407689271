import * as React from 'react';
import { useParams } from 'react-router-dom';
import Subheader from '../components/layout/Subheader';
import DataloggerCharts from '../features/dataloggerCharts/DataloggerCharts';
import DataloggerLoader from '../features/dataloggerList/DataloggerLoader';

const DataloggerDetailsPage: React.FC = () => {
  const { idcode } = useParams();
  return (
    <DataloggerLoader idcode={idcode}>
      {(datalogger) => (
        <>
          <Subheader>{datalogger.name} [{datalogger.idcode}]</Subheader>
          <div>
            <DataloggerCharts datalogger={datalogger} />
          </div>
        </>
      )}
    </DataloggerLoader>
  )
};
export default DataloggerDetailsPage;
