import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { color } from 'features/dataloggerCharts/legacyConfig';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Gauge from './Gauge';

export interface XYChartProps {
  chartData: any;
  imageFilename: string;
  chartId: string;
  axisTitles?: string[];
  reversedAxes?: boolean;
  totalShiftGauge?: boolean;
  totalShiftData?: any;
  gaugeProps?: any;
}

const XYChart = (props: XYChartProps) => {
  const {
    chartData,
    imageFilename,
    chartId,
    axisTitles,
    reversedAxes,
    totalShiftGauge,
    totalShiftData,
    gaugeProps,
  } = props;
  const axisDefaultMax = 1.5;
  const dataMaxValue = Math.max(...chartData[0].data.map((d: any) => Math.abs(d[1])), ...chartData[1].data.map((d: any) => Math.abs(d[1])));
  const axisMaxValue = dataMaxValue > axisDefaultMax ? dataMaxValue : axisDefaultMax;

  const xyData = [];
  if (chartData[0]?.data.length > 0) {
    for (let i = 0; i < chartData[0].data.length; i++) {
      xyData.push({
        x: chartData[0].data[i][1].toFixed(2),
        y: chartData[1].data[i][1].toFixed(2),
        date: chartData[0].data[i][0],
      })
    }
  }

  am4core.useTheme(am4themes_animated);
  let chart = am4core.create(chartId, am4charts.XYChart);
  chart.padding(10, 10, 10, 10);

  function createAxis(list: any, titleText: string) {
    let axis = list.push(new am4charts.ValueAxis());
    axis.title.text = titleText;
    axis.renderer.opposite = true;
    axis.title.rotation = 0;
    axis.title.fontWeight = "bold";
    axis.title.marginLeft = 10;
    axis.title.marginBottom = 10;
    axis.min = -axisMaxValue;
    axis.max = axisMaxValue;
    axis.renderer.minGridDistance = 50;
    axis.renderer.grid.template.disabled = true;
    //axis.renderer.labels.template.disabled = true;
    //axis.renderer.baseGrid.disabled = true;
  }

  // Create axes
  if (reversedAxes) {
    createAxis(chart.yAxes, axisTitles ? axisTitles[0] : "X (mm)");
    createAxis(chart.xAxes, axisTitles ? axisTitles[1] : "Y (mm)");
  } else {
    createAxis(chart.xAxes, axisTitles ? axisTitles[0] : "X (mm)");
    createAxis(chart.yAxes, axisTitles ? axisTitles[1] : "Y (mm)");
  }

  // Create series
  const series = chart.series.push(new am4charts.LineSeries());
  series.data = xyData;
  series.dataFields.valueX = reversedAxes ? "y" : "x";
  series.dataFields.dateX = "date";
  series.dataFields.valueY = reversedAxes ? "x" : "y";
  series.stroke = am4core.color(color.primary._300);
  series.strokeWidth = 2;
  series.connect = false;

  const bullet = series.bullets.push(new am4charts.CircleBullet());
  bullet.properties.scale = 0.5;
  bullet.tooltipText = "X: {x} mm, Y: {y} mm \n{date}";

  const bullethover = bullet.states.create("hover");
  bullethover.properties.scale = 1;

  return (
    <>
      <Row>
        <Col className="text-center">
          <img src={`../${imageFilename}`} alt="XY-Map" style={{ height: "400px" }} />
        </Col>
        <Col>
          <div id={chartId} style={{ maxWidth: "400px", minHeight: "400px", margin: "auto" }}></div>
        </Col>
      </Row>
      {totalShiftGauge && (
        <Row>
          <Col md={1} />
          <Col md={4} className="text-center pt-6">
            <Gauge
              title="Kokonaismuutoksen maksimi"
              data={totalShiftData ? totalShiftData : []}
              grayscale_gauge={true}
              needle_in_middle={false}
              symbol={"mm"}
              number_of_decimals={2}
              {...gaugeProps}
            />
          </Col>
          <Col md={1} />
          <Col md={6}></Col>
        </Row>
      )}
    </>
  )
}

export default XYChart;
