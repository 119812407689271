import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FlagIconFactory from 'react-flag-icon-css';
import { NavDropdown } from 'react-bootstrap';

import moment from 'moment-timezone';
import 'moment/locale/fi';
import 'moment/locale/sv';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface FlagCodes {
  [key: string]: string,
}

const flagCodes : FlagCodes = {
  'en': 'gb',
  'fi': 'fi',
  'sv': 'se'
}

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const selectedLanguage : string = i18n.languages[0];
  moment.locale(selectedLanguage);

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
    moment.locale(locale);
    localStorage.setItem('selectedLanguage', locale);
  }

  return (
      <NavDropdown title={
        <FlagIcon
          code={flagCodes[selectedLanguage]} size='2x'
          className="black-and-white"
        />
      } id="select-language-dropdown">
        <NavDropdown.Item onClick={() => changeLanguage('en')}>
          <FlagIcon code='gb' size='2x' />
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => changeLanguage('fi')}>
          <FlagIcon code='fi' size='2x' className="border" />
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => changeLanguage('sv')}>
          <FlagIcon code='se' size='2x' className="border" />
        </NavDropdown.Item>
      </NavDropdown>
  )
}

export default LanguageSwitcher
