import React, { useRef } from "react";
import ReactExport from "react-export-excel";
import { useState } from "react";
import { apiRequest } from '../../api';
import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExcelExport = ({widget, dashboardTitle, dashboardId, startDate=null, endDate=null}) => {
  const [data, setData] = useState();
  let filename = `${dashboardTitle}`;
  filename += !filename.includes(widget.title) ? ` - ${widget.title}` : '';
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  let queryStr = '';
  if (startDate) { queryStr += `start=${encodeURIComponent(startDate)}&`; }
  if (endDate) { queryStr += `end=${encodeURIComponent(endDate)}`; }

  async function getExcelData() {
    if (data) {
      setData(null);
    }
    const excelData = await apiRequest(
      `/api/ui/v1/dashboards/${dashboardId}/excel_data/${widget.id}/?${queryStr}`
    );
    if (excelData) {
      setData(excelData);
      setTimeout(() => {
        buttonRef.current.click();
      }, 100)
    }
  }

  const ExcelButton = () => {
    return (
      <button type="button" className="btn btn-link float-right" onClick={getExcelData}>
        {t('Download excel')}
      </button>
    )
  }

  let labels;
  if (data) {
    if (['inclinometer-line', 'convergence-line'].includes(widget.type)) {
      labels = Object.keys(data[0]).sort(
        function(a, b) { return parseFloat(a) - parseFloat(b) }
      )
    } else {
      labels = widget.data.sources.map(source => source.title)
      labels.unshift('timestamp')
    }
  }

  // hideElement makes it download multiple times so we do a really ugly hack with refs
  return (
    <>
      {data && (
        <ExcelFile
          element={<button ref={buttonRef} style={{display: "none"}} />}
          filename={filename}
        >
          <ExcelSheet data={data} name={widget.title}>
            {
              labels.map(label => (
                <ExcelColumn
                  key={label}
                  label={label === "timestamp" ? "Aikaleima" : `${label}`}
                  value={label}
                />
              ))
            }
          </ExcelSheet>
        </ExcelFile>
      )}
      <ExcelButton />
    </>
  );
}

export default ExcelExport;
