import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import classNames from 'classnames';
import {RootState} from "app/rootReducer";
import {login} from "./authenticationSlice";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';

const defaultValues = {
  username: '',
  password: '',
  rememberme: true,
}
const LoginForm: React.FC = () => {
  const { register, handleSubmit, errors } = useForm({defaultValues});
  const [loginError, loginInProgress] = useSelector(
    (state: RootState) => [
      state.authentication.loginError,
      state.authentication.loginInProgress
    ]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogin = async (data: any) => {
    dispatch(login({
      username: data.username,
      password: data.password,
      remember: data.rememberme,
    }));
  }

  return (
    <div>
      <h1 className="text-white fw-300 mb-3 d-sm-block d-md-none">
        Secure login
      </h1>
      <div className="card p-4 rounded-plus bg-faded">
        {loginError && (
          <div className="alert alert-danger" role="alert">
            <strong>Error!</strong> {loginError}
          </div>
        )}
        <form noValidate onSubmit={handleSubmit(onLogin)}>
          <div className="form-group">
            <label className="form-label" htmlFor="username">{t('Username')}</label>
            <input
              type="text"
              id="username"
              name="username"
              className={classNames('form-control',  'form-control-lg', {
                'is-invalid': errors.username,
              })}
              placeholder={t('Username')}
              ref={register({ required: true})}
            />
            {errors.username && (
              <div className="invalid-feedback">{t('Enter the username.')}</div>
            )}
            <div className="help-block">{t('Your username to app')}</div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">{t('Password')}</label>
            <input
              type="password"
              id="password"
              name="password"
              className={classNames('form-control',  'form-control-lg', {
                'is-invalid': errors.password,
              })}
              placeholder={t('Password')}
              ref={register({ required: true})}
            />
            {errors.password && (
              <div className="invalid-feedback">{t('Enter the password.')}</div>
            )}
            <div className="help-block">{t('Your password')}</div>
          </div>
          <div className="form-group text-left">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="rememberme"
                id="rememberme"
                ref={register()}
              />
              <label className="custom-control-label" htmlFor="rememberme">{t('Remember me')}</label>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6 pl-lg-1 my-2">
              <button
                id="js-login-btn"
                type="submit"
                className="btn btn-danger btn-block btn-lg"
                disabled={loginInProgress}
              >
                {loginInProgress && (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />{' '}
                  </>
                )}
                {t('Login')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginForm;
